import React, { useState } from "react";
import bg from "../../assets/bg2.png";
import { Box, Paper, TextField } from "@mui/material";
import CustomBox from "../components/Box/CustomBox";
import AddGuestInput from "../components/InputField/AddGuestInput";
import CustomButton from "../components/Button/CustomButton";
import CustomText from "../components/text/CustomText";
import nhance from "../../assets/Common/nhance_logo.png";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidPhoneNumber } from "react-phone-number-input";
import emailValidator from "email-validator";
import { setNavigation } from "../redux/slice/NavigationSlice";
import { useDispatch, useSelector } from "react-redux";
import { startGenerateOtp } from "../redux/slice/TenantSlice";
import { NavigationConstants, tenantConstants } from "../common/Constants";
import { resetAlert, setToastMessage } from "../redux/slice/Common/toastSlice";
import BackgroundImage from "../components/BackgroundImage/BackgroundImage";
import OverlayLoader from "../components/Overlay/OverlayLoader";
import CustomHeader from "../components/Header/CustomHeader";
import { getRelativeHeight } from "../utils/RelativeSize";

const UserLoginScreen = () => {
  const dispatch = useDispatch();
  const hashValue = useSelector((state) => state?.tenantConfig?.hashValue);
  const { logo } = useSelector((state) => state?.tenantConfig);
  const { title } = useSelector((state) => state?.navigation);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChangeName = (e) => {
    const val = e.target.value;
    setName(val);
    validateForm(val, email);
  };

  const handleChangeEmail = (e) => {
    const val = e.target.value;
    setEmail(val);
    if (val.length === 0) {
      setEmailError(false);
    } else {
      setEmailError(!validateEmail(val));
    }

    validateForm(name, val);
  };

  const validateForm = (name, email) => {
    const isNameValid = /^[a-zA-Z ]+$/.test(name.trim());
    const isNameInvalid =
      !isNameValid || /[!@#$%^&*(),.?":{}|<>0-9]/.test(name);

    const isEmailValid =
      email.length > 1 ? emailValidator.validate(email) : true;
    const isNameLengthZero = name.length === 0;
    setIsFormValid(isNameValid && !isNameInvalid && isEmailValid);

    setNameError(!isNameLengthZero && isNameInvalid);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLoader = () => {
    setLoader((prev) => !prev);
  };

  const handleChangePhone = (value) => {
    setPhoneError(false);
    if (value.length === 3) {
      setPhone("");
    } else setPhone(value);
  };

  const handleNavigate = () => {
    dispatch(setNavigation(NavigationConstants?.OTP_INPUT));
    dispatch(
      setToastMessage({ message: "OTP sent successfully", type: "success" })
    );
    setTimeout(() => {
      dispatch(resetAlert());
    }, 3000);
  };
  const handleSubmit = () => {
    handleLoader();
    let data = {
      hashValue,
      userName: name,
    };
    if (email) {
      data.email = email;
    }

    if (phone) {
      data.mobile = phone;
    }
    dispatch(
      startGenerateOtp({
        data,
        handleSuccess: handleNavigate,
        handleLoader: handleLoader,
      })
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
      }}
    >
      <BackgroundImage imageUrl={bg} poweredByLogo={nhance} Logo={logo}>
        <CustomBox>
          <Paper
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "380px",
              top: "50%",
              outline: "0",
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: 4,
              minHeight: "350px",
            }}
          >
            <CustomBox
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              <AddGuestInput
                placeholder="Name"
                height="6vh"
                name="name"
                value={name}
                onChange={handleChangeName}
                width={350}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleChangeEmail}
                error={emailError}
                helperText={emailError ? "Invalid email address" : ""}
                sx={{ mt: 2 }}
                borderRadius={10}
              />
              <MuiPhoneNumber
                defaultCountry={"in"}
                label={"Enter your phone number"}
                value={phone}
                autoFormat={false}
                onlyCountries={["in"]}
                countryCodeEditable={false}
                onChange={handleChangePhone}
                inputProps={{ maxLength: 13 }}
                margin="normal"
                fullWidth
                variant="outlined"
                error={phoneError}
                helperText={phoneError && "Invalid phone"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  sx: { borderRadius: 2 },
                }}
              />

              {nameError && (
                <CustomText sx={{ color: "red", textAlign: "center" }}>
                  Please enter a valid name.
                </CustomText>
              )}

              <CustomBox sx={{ mt: "auto", py: 2 }}>
                <CustomButton
                  height={getRelativeHeight(60)}
                  title="Generate OTP"
                  onClick={handleSubmit}
                  disabled={
                    !isFormValid ||
                    (phone?.length > 1
                      ? phone.length === 13
                        ? false
                        : true
                      : email.length > 1
                      ? false
                      : true)
                  }
                />
              </CustomBox>
            </CustomBox>
          </Paper>
        </CustomBox>
        <OverlayLoader visible={loader} />
      </BackgroundImage>
    </Box>
  );
};

export default UserLoginScreen;
