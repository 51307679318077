import React, { useState } from "react";
import CustomBox from "../components/Box/CustomBox";
import OtpInput from "./OtpInput";
import FRAssetsRaiseFault from "../screens/FaultReporting/FRAssetsRaiseFault";

const FaultReport = () => {
  return (
    <CustomBox>
      <FRAssetsRaiseFault />
    </CustomBox>
  );
};
export default FaultReport;
