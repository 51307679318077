import React, { useEffect, useState } from 'react'
import CustomBox from '../../components/Box/CustomBox'
import {
  Box,
  Pagination,
  Paper,
  Modal,
  Typography,
  IconButton,
  Card,
  Grid,
} from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import CustomText from '../../components/text/CustomText'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import bg from '../../../assets/bg2.png'
import CustomHeader from '../../components/Header/CustomHeader'
import Divider from '@mui/material/Divider'
import { useDispatch, useSelector } from 'react-redux'
import startFetchFrDetails, {
  clearFaultDetails,
  clearImageLink,
  setArea,
  setAssetName,
  setBuilding,
  setFloor,
  setLocation,
  setQrCode,
} from '../../redux/slice/faultReportSlice'
import { useLocation } from 'react-router-dom'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
// import nhance from "../../assets/nhance.svg";
import nhance from '../../../assets/Common/nhance_logo.png'
import { getRelativeHeight, getRelativeWidth } from '../../utils/RelativeSize'
import AMFaultsDetailsComp from '../../ui/Widgets/AMFaultsDetailsComp/AMFaultsDetailsComp'
import backCircle from '../../../assets/Header/backCircle.svg'
import { setNavigation } from '../../redux/slice/NavigationSlice'
import OverlayLoader from '../../components/Overlay/OverlayLoader'
import noServices from '../../../assets/notFound.json'
import Lottie from 'react-lottie'

const FRAssetsDetails = ({ handlePress }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [loader, setLoader] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false) // New state to manage selected item
  const faultReportDetails = useSelector(
    (state) => state?.faultReport?.faultReportDetails?.data,
  )
  const isFaulutReportServiceAvailable = useSelector(
    (state) => state?.tenantConfig?.servicesEnabled,
  )
  const { logo } = useSelector((state) => state?.tenantConfig)
  const dispatch = useDispatch()
  const pageSize = 4

  const totalItems = faultReportDetails?.length
  const totalPages = Math.ceil(totalItems / pageSize)

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handleItemClick = (item) => {
    setSelectedItem(item)
  }

  const handleClose = () => {
    setSelectedItem((prev) => !prev)
  }
  const handleClick = () => {
    dispatch(setNavigation('homePage'))
    handlePress && handlePress()
  }
  const handleLoader = () => {
    setLoader((prev) => !prev)
  }

  const handleClear = () => {
    dispatch(setNavigation('homePage'))
    dispatch(clearFaultDetails())
    dispatch(clearImageLink())
  }

  const handleOpenReportFault = () => {
    dispatch(setAssetName(selectedItem?.assetName))
    dispatch(setBuilding(selectedItem?.building?.name))
    dispatch(setLocation(selectedItem?.location?.name))
    dispatch(setQrCode(selectedItem?.qrCode));
    dispatch(setFloor(selectedItem?.floor?.name))
    dispatch(setArea(selectedItem?.area?.name))
    // dispatch(setLocation());
    dispatch(setNavigation('FRAssetsRaiseFault'))
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
        }}
      >
        <BackgroundImage imageUrl={bg} poweredByLogo={nhance} Logo={logo}>
          <Box sx={{ position: 'absolute' }}>
            <CustomHeader handleClick={handleClear} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Card
              sx={{
                height: '60%',
                display: 'flex',
                flexDirection: 'column',
                width: '90%',
              }}
            >
              {faultReportDetails && faultReportDetails?.length > 0 ? (
                <>
                  <CustomBox sx={{ p: 2 }} xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                      Assets found in the area
                    </Typography>
                  </CustomBox>
                  <Grid
                    container
                    item
                    sx={{
                      flex: 1,
                      overflowY: 'auto',
                      position: 'relative',
                    }}
                    xs={12}
                  >
                    {faultReportDetails
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize,
                      )
                      .map(
                        (
                          {
                            _id,
                            assetName,
                            model,
                            serialNumber,
                            imageLinks,
                            area,
                            floor,
                            location,
                            building,
                            qrCode
                          },
                          index,
                        ) => (
                          <Grid item xs={12} key={_id}>
                            <>
                              <ListItemButton
                                onClick={() =>
                                  handleItemClick({
                                    _id,
                                    assetName,
                                    model,
                                    serialNumber,
                                    imageLinks,
                                    area,
                                    floor,
                                    location,
                                    building,
                                    qrCode
                                  })
                                }
                              >
                                <ListItemAvatar>
                                  {imageLinks && imageLinks.length > 0 ? (
                                    <Avatar
                                      alt="Profile Picture"
                                      src={imageLinks[0]}
                                    />
                                  ) : (
                                    <Avatar />
                                  )}
                                </ListItemAvatar>
                                <ListItemText
                                  primary={assetName}
                                  secondary={`Model: ${model}`}
                                />
                                <ListItemIcon sx={{ marginLeft: '10px' }}>
                                  <ArrowForwardIosIcon />
                                </ListItemIcon>
                              </ListItemButton>
                              <Divider />
                            </>
                          </Grid>
                        ),
                      )}
                  </Grid>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Lottie
                        options={{
                          animationData: noServices || {},
                          loop: true,
                          autoplay: true,
                        }}
                        style={{
                          width: getRelativeWidth(300),
                          height: getRelativeHeight(300),
                        }}
                      />
                      <Box>
                        <Typography variant="h6" textAlign={'center'}>
                          No Assets found in the area
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
              {/* <Box display="flex" justifyContent="center" p={2} xs={12} sx={{border:'1px solid red'}}> */}
              {faultReportDetails?.length > 0 && (
                <Grid container item justifyContent="center">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    color="error"
                  />
                </Grid>
              )}
              {/* </Box> */}
            </Card>
          </Box>
        </BackgroundImage>
      </Box>

      {selectedItem && (
        <AMFaultsDetailsComp
          title="Report a fault"
          open={selectedItem}
          handleClose={handleClose}
          showButtons={isFaulutReportServiceAvailable.includes('faultReport')}
          onButton2Click={handleOpenReportFault}
          // customWidth={getRelativeWidth(400)}
          data={[
            {
              Name: selectedItem?.assetName,
              id: selectedItem?._id,
              SerialNumber: selectedItem?.serialNumber,
              Model: selectedItem?.model,
              Image: selectedItem?.imageLinks,
            },
          ]}
        />
      )}
      <OverlayLoader visible={loader} />
    </>
  )
}

export default FRAssetsDetails
