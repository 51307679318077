import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const CustomText = (props) => {
  const theme = useTheme();
  const { sx } = props;
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"));
  const scaleFactor = window.innerWidth / 414;
  const client = isMobileOrTab ? "pwa" : "web";
  const handleFontStyle = () => {
    let fontObj = {
      ...sx,
    };
    if (sx?.fontSize) {
      fontObj.fontSize =
        client === "pwa" ? sx?.fontSize * scaleFactor : sx?.fontSize;
    }
    return fontObj;
  };
  return <Typography style={handleFontStyle()}>{props.children}</Typography>;
};

export default CustomText;
