import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import NavigationSlice from "../slice/NavigationSlice";
import TenantSlice from "../slice/TenantSlice";
import feedbackSlice from "../slice/feedbackSlice";
import faultReportSlice from "../slice/faultReportSlice";
import AssetListSlice from "../slice/AssetListSlice";
import toastSlice from "../slice/Common/toastSlice";

const storeConfigure = () => {
  const rootReducer = combineReducers({
    navigation: NavigationSlice,
    tenantConfig: TenantSlice,
    feedback: feedbackSlice,
    faultReport: faultReportSlice,
    assetManager: AssetListSlice,
    ToastSlice:toastSlice
  });
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};

export default storeConfigure;
