import React from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { lightTheme } from "../../utils/ThemeProvider";
import {
  getRelativeFontSize,
  getRelativeHeight,
  getRelativeWidth,
} from "../../utils/RelativeSize";

const CustomButton = (props) => {
  const theme = useTheme();
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"));
  const scaleFactorHeight = window.innerHeight / 896;
  const scaleFactorWidth = window.innerWidth / 414;
  const client = isMobileOrTab ? "pwa" : "web";
  const { sx, title, onClick, disabled } = props;
  const style = sx || {};
  const {
    backgroundColor,
    borderWidth,
    children, // Use children prop directly
    width,
    height,
    icon,
    borderColor,
    borderRadius,
    titleFontFamily,
    color,
    fontSize,
  } = style;

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="error"
      startIcon={icon}
      sx={{
        backgroundColor:
          backgroundColor || lightTheme?.palette?.orange?.orange4,
        borderWidth: borderWidth || 0,
        borderColor: borderColor || "inherit",
        borderRadius: borderRadius || 1.5,
        height: 50,
        fontSize: getRelativeFontSize(fontSize) || getRelativeFontSize(14),
        fontWeight: "bold",
        fontFamily: titleFontFamily || "SegoeUI-Bold",
        color: color || "#fff",
        width: getRelativeWidth(width) || "100%",
      }}
      disabled={disabled}
    >
      {title?.toUpperCase()}
    </Button>
  );
};

export default CustomButton;
