import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  title: "homePage",
  client: "",
  height: 0,
  width: 0,
};

const NavigationSlice = createSlice({
  name: "NavigationSlice",
  initialState,
  reducers: {
    setNavigation: (state, action) => {
      state.title = action.payload;
    },
    setClientType: (state, action) => {
      state.height = action?.payload?.height;
      state.width = action?.payload?.width;
      state.client = action?.payload?.client;
    },
  },
});

export const { setNavigation, setClientType } = NavigationSlice.actions;

export default NavigationSlice.reducer;
