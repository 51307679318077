import { styled } from '@mui/system'
import { Box ,Button,Card, Modal} from '@mui/material';

export const MuiBox = styled(Box)({
    height:'70vh'
  }); 

export const ModalBox = styled(Box)({
    
  }); 
  export const CameraBox = styled(Box)({
        width:'90%'
  }); 

  export const ImageBox=styled(Box)({
    display:"flex",
     justifyContent:"center",
      width:'100%',
      height: "85vh",
      display:'inline-block',
    position:'relative',
  })

export const ModalCard=styled(Card)({
     
    width: '100%',
    padding: 10,
    outline:'none',
    borderRadius:'10px',

})

export const GalleryButton=styled(Button)({
backgroundColor:'#FF6520',
textTransform:'none',
color:'black'
})

export const GalleryBox = styled(Box)({
    padding:2,
    borderRadius:'5px',
    display:'flex',
    justifyContent:'center'
}); 

export const CameraIconBox = styled(Box)({
  display:'flex',
  justifyContent:'center'
});

export const UploadButton=styled(Button)({
  bgColor:'orange',
  color:'orange',
  padding:1
})

export const ConfirmationBox=styled(Modal)({
  position: "absolute",
  bottom: 0,
  height:'20vh',
  width: "100%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "20px 20px 0px 0px",
  outline:'none'
})

export const imageStyle={
  // width: "100%", 
  height: "100%",
  objectFit: 'cover',

}

export const inputStyle={
  display: "none"
}

export const BackButtonStyle={
  position: 'absolute',
  // bottom:'4em',
  // right:'4em',
  top:'2em',
  height:'18px',
  borderRadius:'1.5em',
  color:'white',
  textTransform:'uppercase',
  padding:'1em 1.5em',
  
  display:'flex',
  alignItems:'center',
  
}

export const CardStyle={
  position: "absolute",
    bottom: 0,
    height:'22vh',
    width: "100%",
    bgcolor: "background.paper",
    p: 4,
    borderRadius: "20px 20px 0px 0px",
    outline:'none',
}

export const AlignedBox=styled(Box)({
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
})