import { InputAdornment, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import CustomText from '../text/CustomText'
import CloseIcon from '@mui/icons-material/Close'
const CustomNameInput = (props) => {
  const {
    name,
    placeholder,
    title,
    inputValue,
    onChange,
    crossIcon,
    handleCrossIconClick,
    disabled,
  } = props

  // const handleChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  return (
    <>
      <Stack spacing={1} pt={2}>
        <CustomText>{title}</CustomText>
        <TextField
          InputProps={{
            endAdornment: crossIcon && (
              <InputAdornment position="end">
                <CloseIcon onClick={handleCrossIconClick} />
              </InputAdornment>
            ),
          }}
          fullWidth
          id={name}
          name={name}
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          disabled={disabled}
        />
      </Stack>
    </>
  )
}
export default CustomNameInput
