import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import leftArrow from "../../../assets/Header/left-arrow.png";
import backCircle from "../../../../src/assets/Header/backCircle.svg";
import { setNavigation } from "../../redux/slice/NavigationSlice";

const CustomHeader = ({ title, handlePress, backLink , handleClick}) => {
  // const navigate = useNavigate()
  const dispatch = useDispatch();

  // const handleClick = () => {
  //   dispatch(setNavigation("homePage"));
  //   handlePress && handlePress();
  // };

  return (
    <Box p={0} pb={0}>
      <Box sx={{ width: "100%", minHeight: "100%" }}>
        <Grid
          container
          spacing={0}
          display="flex"
          alignItems={"center"}
          height={70}
          // backgroundColor="#FF6520"
          p={2}
        >
          <Grid item xs={1.2}>
            <IconButton size="small" float="left" onClick={() => handleClick()}>
              <img src={backCircle} width={"30px"} />
            </IconButton>
          </Grid>
          <Grid item xs={9.8} display="flex">
            <Typography fontSize={20} align="left" color={"#0000000"} ml={2}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default CustomHeader;
