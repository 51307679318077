import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CustomBox from "../Box/CustomBox";
import CustomText from "../text/CustomText";
import { Grid, Stack } from "@mui/material";
import cameraIcon from "../../../assets/Common/camera.svg";
import folderIcon from "../../../assets/Common/folder.svg";
import {
  AlignedBox,
  inputStyle,
} from "../../ui/Styles/CameraOptionModalStyles";
import { getRelativeHeight } from "../../utils/RelativeSize";

const CameraOptionModal = ({ isOpen, onClose, onImageSelect }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 3,
  };
  const [cameraOpen, setCameraOpen] = useState(false);
  const toggleCameraOpen = () => {
    setCameraOpen(true);
  };

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        // const reader = new FileReader();
        // let formData = new FormData();
        // console.log({ formData });
        // formData.append("file", file);
        const url = URL.createObjectURL(file);
        onImageSelect({ url, formData: file });
        // reader.onload = (e) => {
        //   const dataUrl = e.target.result;
        //   onImageSelect(dataUrl, formData);
        // };

        // reader.readAsDataURL(file);
        onClose();
      }
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <CustomBox sx={style}>
          <Grid container item xs={12}>
            <Grid item xs={6} onClick={toggleCameraOpen}>
              <AlignedBox>
                <label htmlFor="icon-button-file">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={"center"}>
                      <img src={cameraIcon} width={getRelativeHeight(30)} />
                    </Box>
                    <CustomText color="#212121">Click an image</CustomText>
                  </Stack>
                </label>

                <input
                  accept="image/*"
                  style={inputStyle}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                />
              </AlignedBox>
            </Grid>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="choose-file" id="choose-file-label">
                  <Stack spacing={1} onClick={toggleCameraOpen}>
                    <Box display="flex" justifyContent={"center"}>
                      <img src={folderIcon} width={getRelativeHeight(30)} />
                    </Box>
                    <CustomText color="#212121">Upload from Gallery</CustomText>
                  </Stack>
                </label>

                <input
                  name="uploadDocument"
                  type="file"
                  id="choose-file"
                  accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                  style={inputStyle}
                  onChange={(e) => {
                    handleCapture(e.target);
                  }}
                />
              </AlignedBox>
            </Grid>
          </Grid>
        </CustomBox>
      </Modal>
    </>
  );
};

export default CameraOptionModal;
