import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationConstants } from "../common/Constants";
import HomePage from "../pages/HomePage";
import Feedback from "../pages/Feedback";
import loader from "../../assets/loader.json";
import {
  setTenantConfig,
  startFetchTenantConfig,
} from "../redux/slice/TenantSlice";
import FaultReport from "../pages/FaultReport";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import FRAssetsArea from "../screens/FaultReporting/FRAssetsDetails";
import UserLoginScreen from "../screens/UserLoginScreen";
import AssetDetailsModal from "../screens/AssetManagement/AssetDetailsPage";
import AssetDetailsPage from "../screens/AssetManagement/AssetDetailsPage";
import FRAssetsRaiseFault from "../screens/FaultReporting/FRAssetsRaiseFault";
import OtpInput from "../pages/OtpInput";
import AssetManagement from "../pages/AssetManagement";
import GratitudeMessage from "../pages/GratitudeMessage";
import FRAssetsDetails from "../screens/FaultReporting/FRAssetsDetails";
import NoServicePage from "../screens/NoServicePage";

const Navigation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessEnabled, isLoaded, servicesEnabled } = useSelector(
    (state) => state?.tenantConfig
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
  };
  //   useEffect(() => {
  //     const encodedString = location.pathname?.split("/");
  //     console.log({ encodedString });
  //     // dispatch(startFetchTenantConfig());
  //     setTimeout(() => {
  //       dispatch(
  //         setTenantConfig({
  //           isPublicAccessServiceEnabled: true,
  //           internalServicesEnabled: [
  //             "fault report",
  //             "asset management",
  //             "feedback",
  //           ],
  //         })
  //       );
  //     }, 1000);
  //   }, []);
  const { title } = useSelector((state) => state?.navigation);
  return (
    <Box sx={{ height: "100%"}}>
      {title === NavigationConstants?.HOME_PAGE ? (
        <HomePage />
      ) : // <GratitudeMessage />
      title === NavigationConstants?.FAULT_REPORT ? (
        <FaultReport />
      ) : title === NavigationConstants?.FEED_BACK ? (
        <Feedback />
      ) : title === NavigationConstants?.ASSET_MANAGEMENT ? (
        // <FaultReport />
        <AssetManagement />
      ) : title === NavigationConstants?.FR_RAISE_FAULT ? (
        <FRAssetsRaiseFault />
      ) : title === NavigationConstants?.USER_DETAILS ? (
        <UserLoginScreen />
      ) : title === NavigationConstants?.OTP_INPUT ? (
        <OtpInput />
      ) : title === NavigationConstants?.GRATITUDE_MESSAGE ? (
        <GratitudeMessage />
      ) : title === NavigationConstants?.ASSET_DETAILS ? (
        <FRAssetsDetails />
      ) : title === NavigationConstants?.NO_SERVICE ? (
        <NoServicePage />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Navigation;
