export const NavigationConstants = {
  HOME_PAGE: "homePage",
  FAULT_REPORT: "faultReport",
  FEED_BACK: "feedback",
  FRMODALSCREEN: "frModalScreen",
  ASSET_MANAGEMENT: "assetManagement",
  FR_RAISE_FAULT: "FRAssetsRaiseFault",
  USER_DETAILS: "userDetails",
  OTP_INPUT: "OtpInput",
  GRATITUDE_MESSAGE: "gratitudeMessage",
  ASSET_DETAILS: "frAssetDetails",
  NO_SERVICE: "noServicePage",
};
export const tenantConstants = {
  faultReport: "Report a Fault",
  assetManagement: "assets in the area",
  feedback: "give a feedback",
  faultReport: " raise a fault",
  FAULT_REPORT: "faultReport",
  ASSET_MANAGEMENT: "assetManagement",
  FEEDBACK: "feedback",
  USER_DETAILS: "userDetails",
  OTP_INPUT: "OtpInput",
};

export const apiConstants = {
  QR_VALIDATE: "v1/scan",
  QR_ASSETS: "v1/assets",
  ASSET_DETAILS: "v1/fault-details",
  RAISE_FAULT: "v1/fault",
  FB_RATING: "v1/rating",
  FB_AREA_DETAILS: "v1/area-details",
  GENERATE_OTP: "v1/generate-otp",
  LOGO_LINK: "https://twin-development.s3.ap-south-1.amazonaws.com/",
};
export const FaultReportConstants = {};

export const FBConstants = {
  FETCH_FEEDBACKS: "fb/v1/ratings?buildingId=",
  FETCH_AREA_RATINGS: "fb/v1/areas?buildingId=",
  FETCH_AREA_DETAILS: "fb/v1/qrcode?qrcode=",
  CREATE_FEEDBACK: "fb/v1/rating",
  FETCH_SINGLE_FEEDBACK: "fb/v1/rating/",

  WANT_TO_PROVIDE_FEEDBACK: "Want to provide feedback?",
  SCAN_AREA_QRCODE: "Scan Area QR code to rate",
  ZONES: "Zones",
  MY_RATINGS: "  My Ratings",
  NO_FEEDBACKS_GIVEN_YET: "No Feedbacks given yet",
  OVERALL_RATING: "Overall Rating",
  YOU_RATED: "You Rated",
  YOUR_FEEDBACK: "Your Feedback",
  RATE_FEEDBACK: "Please rate your feedback for this area",
  ADD_COMMENTS: "Add comments",
  ENTER_HERE: "Enter here",
  SUBMIT: "Submit",
  COME_BACK_TOMMORROW_RATE_US_TEXT: "Come back tomorrow to rate again!",
  THANKYOU: "Thank you!",
  FEEDBACK_SUCCESSFULL_TEXT: "Your feedback was successfully submitted",
  
};
export const OtpInputConstants = {
    INVALID_OTP: "Invalid OTP",
  RESEND: "Resend",
  FAULT_SUBMIT: "By clicking the Submit button, you will be validating the OTP and submitting the fault report",
  FEEDBACK_SUBMIT: " By clicking the Submit button, you will be validating the OTP and submitting the feedback",
  ENTER_YOUR: "Enter your",
  VERIFICATION_CODE: "Verification code",
  WE_HAVE_SENT: 'We have sent you a One Time Passcode'
}
