import React from 'react'
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
} from '@mui/material'
import CustomText from '../text/CustomText'

const ReusableRadioGroup = ({ radioButton, handleChange, other, title , handleChangeRadio }) => {
  return (
    <>
        <CustomText>{title}</CustomText>
      <Box p={1} pt={0} pb={0.5}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            color="#AAAAAA"
            value={radioButton}
            name="radio"
            onChange={(e) => handleChange(e)}
          >
            <FormControlLabel
              value="Not working as intended"
              control={<Radio color="warning" />}
              label="Not working as intended"
            />
            <FormControlLabel
              value="Its Broken"
              control={<Radio color="warning" />}
              label="Its Broken"
            />
            <FormControlLabel
              value="Need Maintainance soon"
              control={<Radio color="warning" />}
              label="Need Maintenance soon"
            />
            <FormControlLabel
              value="Need replacement ASAP"
              control={<Radio color="warning" />}
              label="Need replacement ASAP"
            />
            <FormControlLabel
              value="Requires part replacement"
              control={<Radio color="warning" />}
              label="Requires part replacement"
            />
            <FormControlLabel
              value="other"
              control={<Radio color="warning" />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
        <Stack sx={{ width: '100%' }}>
          {radioButton === 'other' ? (
            <TextField
              value={other}
              name="other"
              placeholder="Enter the asset fault in short"
              variant="outlined"
              inputProps={{ maxLength: 30 }}
              onChange={(e) => handleChangeRadio(e)}
            />
          ) : (
            ''
          )}
        </Stack>
      </Box>
    </>
  )
}

export default ReusableRadioGroup
