import { useSelector } from "react-redux";
import storeConfigure from "../redux/store/Store";

export const getRelativeWidth = (width) => {
  const deviceWidth = storeConfigure().getState()?.navigation?.width;
  const client = deviceWidth < 600 ? "pwa" : "web";
  const scaleFactorWidth = window.innerWidth / 414;
  const updatedWidth = client === "pwa" ? width * scaleFactorWidth : width;
  return updatedWidth;
};

export const getRelativeHeight = (height) => {
  const deviceWidth = storeConfigure().getState()?.navigation?.width;
  const client = deviceWidth < 600 ? "pwa" : "web";
  const scaleFactorHeight = window.innerHeight / 896;
  const updatedHeight = client === "pwa" ? height * scaleFactorHeight : height;
  return updatedHeight;
};

export const getRelativeFontSize = (fontSize) => {
  const deviceWidth = storeConfigure().getState()?.navigation?.width;
  const client = deviceWidth < 600 ? "pwa" : "web";
  const scaleFactorWidth = window.innerWidth / 414;
  const updatedFontSize =
    client === "pwa" ? fontSize * scaleFactorWidth : fontSize;
  return updatedFontSize;
};
