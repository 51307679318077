import React, { useEffect, useState } from "react";
import { getRelativeWidth } from "../../utils/RelativeSize";
import { styled } from "@mui/material/styles";
import { setAssetDetails } from "../../redux/slice/AssetListSlice";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import AMFaultsDetailsComp from "../../ui/Widgets/AMFaultsDetailsComp/AMFaultsDetailsComp";
import CustomBox from "../../components/Box/CustomBox";
import {
  setArea,
  setAssetName,
  setBuilding,
  setFloor,
  setLocation,
} from "../../redux/slice/faultReportSlice";
import { setNavigation } from "../../redux/slice/NavigationSlice";
import { NavigationConstants } from "../../common/Constants";

const AssetDetailsPage = () => {
  const faultReportingEnabled = useSelector(
    (state) => state?.tenantConfig?.faultReportingEnabled
  );

  const [modal, setModal] = useState(true);
  const dispatch = useDispatch();

  const assetDetails = useSelector((state) => state.tenantConfig?.assetDetails);

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
//  dispatch(setAssetName(selectedItem?.assetName))
//     dispatch(setBuilding(selectedItem?.building?.name))
//     dispatch(setLocation(selectedItem?.location?.name))
//     dispatch(setFloor(selectedItem?.floor?.name))
//     dispatch(setArea(selectedItem?.area?.name))
//     // dispatch(setLocation());
//     dispatch(setNavigation('FRAssetsRaiseFault'))
  const handleRaiseFault = () => {
    dispatch(setLocation(assetDetails?.location?.name));
    dispatch(setBuilding(assetDetails?.building?.name));
    dispatch(setFloor(assetDetails?.floor?.name));
    dispatch(setArea(assetDetails?.area?.name));
    if (assetDetails?.assetName)
      dispatch(setAssetName(assetDetails?.assetName));
    dispatch(setNavigation(NavigationConstants?.FAULT_REPORT));
  };
  return (
    <CustomBox>
      <AMFaultsDetailsComp
        open={modal}
        showButtons={faultReportingEnabled}
        handleClose={() => console.log("Modal Closed")}
        customWidth={getRelativeWidth(350)}
        onButton2Click={handleRaiseFault}
        hideAssetId={true}
        data={[
          {
            Name: assetDetails?.assetName,
            Model: assetDetails?.model,
            Image: assetDetails?.imageLinks,
            SerialNumber: assetDetails?.serialNumber,
            id: assetDetails?._id,
            //   Warranty: "Expires Soon",
          },
        ]}
      />
    </CustomBox>
  );
};

export default AssetDetailsPage;
