import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { getRelativeHeight, getRelativeWidth } from "../../utils/RelativeSize";

const CustomBox = (props) => {
  const theme = useTheme();
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"));
  const scaleFactorHeight = window.innerHeight / 896;
  const scaleFactorWidth = window.innerWidth / 414;
  const client = isMobileOrTab ? "pwa" : "web";
  const { sx } = props;
  const handleStyleObj = (ele) => {
    let obj = { ...ele };
    if (ele?.border) {
      obj.border = ele?.border;
    }
    if (ele?.height) {
      if (String(ele?.height)?.includes("%")) {
        obj.height = ele?.height;
      } else {
        obj.height = getRelativeHeight(ele?.height);
      }
    }
    if (ele?.left) {
      if (String(ele?.left)?.includes("%")) {
        obj.left = ele?.left;
      } else {
        obj.left = client === "pwa" ? ele?.left * scaleFactorWidth : ele?.left;
      }
    }
    if (ele?.right) {
      if (String(ele?.right)?.includes("%")) {
        obj.right = ele?.right;
      } else {
        obj.right =
          client === "pwa" ? ele?.right * scaleFactorWidth : ele?.right;
      }
    }
    if (ele?.top) {
      if (String(ele?.top)?.includes("%")) {
        obj.top = ele?.top;
      } else {
        obj.top = ele?.top * scaleFactorHeight;
      }
    }
    if (ele?.bottom) {
      if (String(ele?.bottom)?.includes("%")) {
        obj.bottom = ele?.bottom;
      } else {
        obj.bottom = ele?.bottom * scaleFactorHeight;
      }
    }
    if (ele?.minHeight) {
      if (String(ele?.minHeight)?.includes("%")) {
        obj.minHeight = ele?.minHeight;
      } else {
        obj.minHeight = ele?.minHeight * scaleFactorHeight;
      }
    }
    if (ele?.width) {
      if (String(ele?.width)?.includes("%")) {
        obj.width = ele?.width;
      } else {
        obj.width = getRelativeWidth(ele?.width);
      }
    }
    if (ele?.maxWidth) {
      if (String(ele?.maxWidth)?.includes("%")) {
        obj.maxWidth = ele?.maxWidth;
      } else {
        obj.maxWidth = getRelativeWidth(ele?.maxWidth);
      }
    } else {
      obj.maxWidth = 600;
    }
    if (ele?.minWidth) {
      if (String(ele?.minWidth)?.includes("%")) {
        obj.minWidth = ele?.minWidth;
      } else {
        obj.minWidth =
          client === "pwa" ? ele?.minWidth * scaleFactorWidth : ele?.minWidth;
      }
    }
    if (ele?.padding) {
      if (String(ele?.padding)?.includes("%")) {
        obj.padding = ele?.padding;
      } else {
        obj.padding =
          client === "pwa" ? ele?.padding * scaleFactorWidth : ele?.padding;
      }
    }
    if (ele?.paddingX) {
      if (String(ele?.paddingX)?.includes("%")) {
        obj.paddingX = ele?.paddingX;
      } else {
        obj.paddingX =
          client === "pwa" ? getRelativeWidth(ele?.paddingX) : ele?.paddingX;
      }
    }
    if (ele?.paddingY) {
      if (String(ele?.paddingY)?.includes("%")) {
        obj.paddingY = ele?.paddingY;
      } else {
        obj.paddingY = ele?.paddingY * scaleFactorHeight;
      }
    }

    if (ele?.paddingTop) {
      if (String(ele?.paddingTop)?.includes("%")) {
        obj.paddingTop = ele?.paddingTop;
      } else {
        obj.paddingTop = ele?.paddingTop * scaleFactorHeight;
      }
    }
    if (ele?.paddingBottom) {
      if (String(ele?.paddingBottom)?.includes("%")) {
        obj.paddingBottom = ele?.paddingBottom;
      } else {
        obj.paddingBottom = ele?.paddingBottom * scaleFactorHeight;
      }
    }
    if (ele?.marginTop) {
      if (String(ele?.marginTop)?.includes("%")) {
        obj.marginTop = ele?.marginTop;
      } else {
        obj.marginTop = ele?.marginTop * scaleFactorHeight;
      }
    }
    if (ele?.marginBottom) {
      if (String(ele?.marginBottom)?.includes("%")) {
        obj.marginBottom = ele?.marginBottom;
      } else {
        obj.marginBottom = ele?.marginBottom * scaleFactorHeight;
      }
    }
    if (ele?.marginLeft) {
      if (String(ele?.marginLeft)?.includes("%")) {
        obj.marginLeft = ele?.marginLeft;
      } else {
        obj.marginLeft =
          client === "pwa"
            ? ele?.marginLeft * scaleFactorWidth
            : ele?.marginLeft;
      }
    }
    if (ele?.marginRight) {
      if (String(ele?.marginRight)?.includes("%")) {
        obj.marginRight = ele?.marginRight;
      } else {
        obj.marginRight =
          client === "pwa"
            ? ele?.marginRight * scaleFactorWidth
            : ele?.marginRight;
      }
    }

    if (ele?.marginHorizontal) {
      if (String(ele?.marginHorizontal)?.includes("%")) {
        obj.marginHorizontal = ele?.marginHorizontal;
      } else {
        obj.marginHorizontal =
          client === "pwa"
            ? ele?.marginHorizontal * scaleFactorWidth
            : ele?.marginHorizontal;
      }
    }
    if (ele?.borderRadius) {
      if (String(ele?.borderRadius)?.includes("%")) {
        obj.borderRadius = ele?.borderRadius;
      } else {
        obj.borderRadius =
          client === "pwa"
            ? ele?.borderRadius * scaleFactorHeight
            : ele?.borderRadius;
      }
    }
    return obj;
  };
  const handleStyle = () => {
    if (sx) {
      const styleIsArray = Array.isArray(sx);

      let styleArr = [];
      if (styleIsArray) {
        for (let i = 0; i < sx?.length; i++) {
          const modifiedStyle = handleStyleObj(sx[i]);
          styleArr.push(modifiedStyle);
        }
      } else {
        const modifiedStyle = handleStyleObj(sx);
        styleArr.push(modifiedStyle);
      }
      return styleArr;
    }
  };
  return <Box sx={handleStyle()}>{props.children}</Box>;
};

export default CustomBox;
