import React from 'react'
import CustomBox from '../components/Box/CustomBox'
import Lottie from 'react-lottie'
import computer from '../../assets/computer.json'
import CustomText from '../components/text/CustomText'
import nhance from "../../assets/Common/nhance_logo.png"
import bg from "../../assets/bg2.png"
import { Box } from '@mui/system'
import BackgroundImage from '../components/BackgroundImage/BackgroundImage'
import { useSelector } from 'react-redux'

const NoServicePage = () => {
    const { logo } = useSelector((state) => state?.tenantConfig);

  return (
    <>
       <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
      }}
    >
      <BackgroundImage imageUrl={bg} poweredByLogo={nhance}>
        <CustomBox sx={{ height: '100%', width: '100%' }}>
        <CustomBox
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CustomBox
            sx={{
              height: 270,
              border: "solid 1px #eeeeee",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: 320,
              bgcolor: "#ffffff",
              boxShadow: 20,
              padding: 2,
              borderRadius: 2,
            }}
          >
            <CustomBox sx={{ paddingX: 2 }}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: computer,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={120}
                width={120}
              />
              <CustomText
                sx={{
                  fontSize: 24,
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                No services available
              </CustomText>
            </CustomBox>
          </CustomBox>
        </CustomBox>
          </CustomBox>
        </BackgroundImage>
        </Box>
    </>
  );
}

export default NoServicePage;

