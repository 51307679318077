import { FormControl, OutlinedInput } from "@mui/material";
import React from "react";

const AddGuestInput = (props) => {
  const {
    name,
    value,
    onChange,
    isError,
    width,
    onBlur, 
    onFocus,
    placeholder,
    height,
    disabled,
    marginTop,
  } = props;
  return (
    <FormControl fullWidth={true}>
      <OutlinedInput
        placeholder={placeholder || "Enter guest email ID"}
        style={{
          backgroundColor: "#FFFFFF",
          height: height || "8vh",
          width: width || "100%",
          borderRadius: "5px",
          color: isError ? "red" : "inherit",
          marginTop: marginTop,
        }}
        name={name}
        onBlur={onBlur} 
        onFocus={onFocus} 
        value={value}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
      />
    </FormControl>
  );
};

export default AddGuestInput;
