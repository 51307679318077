import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FBConstants, apiConstants } from "../../common/Constants";
import { POST } from "../../api/http";
import { resetAlert, setToastMessage } from "./Common/toastSlice";
import { errorHandler } from "../../components/errorHandler";

const initialState = {
  isLoaded: false,
  areaDetails: {},
  userRatings: {},
  userFeedback: {},
  areaDetailsIsLoaded: false,
};

export const fetchAreaDetails = createAsyncThunk(
  "feedbackSlice/fetchAreaDetails",
  async ({ data, handleSuccess, handleLoader }, { dispatch }) => {
    try {
      const response = await POST(`${apiConstants?.FB_AREA_DETAILS}`, data);

      if (response.status === 200) {
        handleLoader && handleLoader();
        const data = response?.data;
        dispatch(setAreaDetails(data));
        handleSuccess && handleSuccess();
      }
    } catch (err) {
      handleLoader && handleLoader();
      errorHandler({ err: err?.response, dispatch });
      // errorHandler({ err: err?.response });
      // handleLoader && handleLoader();
      console.error(err);
    }
  }
);
export const startUserRating = createAsyncThunk(
  "feedbackSlice/startUserRating",
  async (
    { data, handleSuccess, handleLoader, toggleInvalidOtp, clearInvalidOtp },
    { dispatch }
  ) => {
    try {
      const response = await POST(`${apiConstants?.FB_RATING}`, data);

      if (response.status === 201) {
        handleLoader && handleLoader();
        clearInvalidOtp && clearInvalidOtp();
        // toggleInvalidOtp && toggleInvalidOtp({ success: true });
        const data = response?.data;
        dispatch(setUserRatings(data));
        handleSuccess && handleSuccess();
      }
    } catch (err) {
      handleLoader && handleLoader();
      //   alert(JSON.stringify(err?.response));
      // handleLoader && handleLoader();
      errorHandler({ err: err?.response, handleLoader, dispatch });
      console.log({ err });
    }
  }
);
// export const startFetchFrDetails = createAsyncThunk(
//   "FaultReportSlice/startFetchFrDetails",
//   async ({ data }, { dispatch }) => {
//     console.log({ data }, { dispatch });
//     try {
//       const response = await POST(`${apiConstants?.QR_ASSETS}`, data);
//       if (response?.status === 200) {
//         const data = response?.data;
//         console.log({ response: response?.data });
//         dispatch(setFrDetails(data));
//       }
//     } catch (err) {
//       console.log({ err });
//     }
//   }
// );

const feedbackSlice = createSlice({
  name: "feedbackSlice",
  initialState,
  reducers: {
    setAreaDetails: (state, action) => {
      state.areaDetails = action.payload;
    },
    setUserRatings: (state, action) => {
      state.userRatings = action.payload;
    },
    setUserFeedback: (state, action) => {
      state.userFeedback = action.payload;
    },
    clearUserRatingFeedback: (state, action) => {
      state.userRatings = initialState.userRatings;
      state.userFeedback = initialState.userFeedback;
    },
  },
  // extraReducers(builder) {
  //   builder.addCase(fetchAreaDetails.fulfilled, (state, action) => {
  //     state.areaDetails = action.payload;
  //     state.areaDetailsIsLoaded = true;
  //   });

  // builder.addCase(startUserRating.fulfilled, (state, action) => {
  //   if (action.payload) {
  //     state.selectedRating = { rating: action.payload, isLoaded: true };
  //   }
  // });
  // },
});
export const {
  setAreaDetails,
  setUserRatings,
  setUserFeedback,
  clearUserRatingFeedback,
} = feedbackSlice.actions;
export default feedbackSlice.reducer;
