import React, { useEffect, useMemo } from "react";
import { Snackbar, SnackbarContent, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetAlert } from "../app/redux/slice/Common/toastSlice";

const AlertComponent = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.ToastSlice.message);
  const type = useSelector((state) => state.ToastSlice.type);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(resetAlert());
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const backgroundColor = type === "success" ? "green" : "red";

  const getWidth = useMemo(() => {
    return errorMessage.length > 40 && 400;
  }, [errorMessage]);

  const getHeight = useMemo(() => {
    return errorMessage.length > 40 ? 50 : 40;
  }, [errorMessage]);

  return (
    <Snackbar
      open={errorMessage}
      message={errorMessage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={() => dispatch(resetAlert())}
      style={{ maxWidth: getWidth }}
      autoHideDuration={3000}
    >
      <SnackbarContent
        message={
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
            }}
          >
            {errorMessage}
          </Box>
        }
        style={{
          backgroundColor: backgroundColor,
          color: "white",
          height: getHeight,
          width: getWidth,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    </Snackbar>
  );
};

export default AlertComponent;
