import axios from "axios";

const setHeaders = () => {
  let headers = {};
  let token = localStorage.getItem("token");
  headers["Content-Type"] = "application/json";
  if (token) {
    headers["Authorization"] = token;
  }
  headers['x-csrf-token'] = localStorage.getItem('csrfToken');
  return headers;
};
let headers = setHeaders();

export const GET_CSRF = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}v1/csrf`, { withCredentials: true })
    if (response?.status === 200) {
      localStorage.setItem('csrfToken', response?.data?.csrf_token)
    }
  } catch (error) {
    console.log(error);
  }
}

export const POST = (endpoint, body, showCsrfError) => {
  const headers = setHeaders();
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + endpoint,
        body,
        { headers, withCredentials: true },
      );
      resolve(response);
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
      reject(e)
      // throw err;
    }
  });
};

export const FILEPOST = (endpoint, body, showCsrfError) => {
  console.log({ endpoint });
  const headers = {
    "content-type": "multipart/form-data",
    Authorization: localStorage.getItem("token"),
    'x-csrf-token': localStorage.getItem('csrfToken')
  };
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + endpoint,
        body,
        { headers, withCredentials: true }
      );
      resolve(response);
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
      reject(e);
    }
  });
};

export const PUT = (endpoint, body, showCsrfError) => {
  const headers = setHeaders();

  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + endpoint,
        body,
        { headers, withCredentials: true }
      );
      resolve(response);
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
      reject(e);
    }
  });
};

export const GET = (endpoint, decodedToken) => {
  const setGetHeaders = () => {
    let headers = {};
    let token = localStorage.getItem("token");
    headers["Content-Type"] = "application/json";
    if (token) {
      headers["Authorization"] = token;
    } else {
      headers["Authorization"] = decodedToken;
    }
    return headers;
  };
  let headers = setGetHeaders();
  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + endpoint,
        { headers }
      );
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
};

export const DELETE = (endpoint, data, showCsrfError) => {
  const headers = setHeaders();

  return new Promise(async function (resolve, reject) {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_BASE_URL + endpoint,
        { headers, withCredentials: true }
      );
      resolve(response);
    } catch (e) {
      console.log(e);
      if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
      reject(e);
    }
  });
};
