import React from "react";
import CustomBox from "../components/Box/CustomBox";
import Lottie from "react-lottie";
import thankyou from "../../assets/Common/tick.json";
import { getRelativeHeight, getRelativeWidth } from "../utils/RelativeSize";
import CustomText from "../components/text/CustomText";
import { useSelector } from "react-redux";
import { tenantConstants } from "../common/Constants";

const GratitudeMessage = () => {
  const { userDetails, selectedOption, hashValue } = useSelector(
    (state) => state?.tenantConfig
  );
  return (
    <CustomBox sx={{ width: "100%", height: "100%" }}>
      <CustomBox
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CustomBox sx={{ width: "100%" }}>
          <Lottie
            options={{
              animationData: thankyou || {},
              loop: true,
              autoplay: true,
            }}
            style={{
              width: getRelativeWidth(300),
              height: getRelativeHeight(300),
            }}
          />
          <CustomBox sx={{ width: "98%" }}>
            {selectedOption === tenantConstants?.FEEDBACK ? (
              <>
                <CustomText sx={{ textAlign: "center" }}>
                  You have successfully given a feedback!
                </CustomText>
                <CustomText sx={{ textAlign: "center" }}>
                  we appreciate your contribution to our improvement.
                </CustomText>
              </>
            ) : (
              <>
                <CustomText sx={{ textAlign: "center" }}>
                  You have successfully raised a fault report!
                </CustomText>
                <CustomText sx={{ textAlign: "center" }}>
                  We appreciate your vigilance in helping us maintain a smooth
                  operation.
                </CustomText>
              </>
            )}
          </CustomBox>
        </CustomBox>
      </CustomBox>
    </CustomBox>
  );
};
export default GratitudeMessage;
