import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from "../../api/http";
import { apiConstants } from "../../common/Constants";
import { setToastMessage } from "./Common/toastSlice";
import { errorHandler } from "../../components/errorHandler";
import { GET_CSRF } from "../../api/http";

const initialState = {
  isLoaded: false,
  servicesEnabled: [],
  logo: "",
  error: "",
  isAssetQR: false,
  faultReportingEnabled: false,
  areaImages: [],
  fileReadUrl: "",
  assetDetails: {},
  hashValue: "",
  selectedOption: "",
  userDetails: {
    name: "",
    email: "",
    phone: "",
  },
};

export const startFetchTenantConfig = createAsyncThunk(
  "TenantSlice/startFetchTenantConfig",
  async ({ data }, { dispatch }) => {
    try {
      const response = await POST(`${apiConstants?.QR_VALIDATE}`, data);
      if (response?.status === 200) {
        const responseData = response?.data;
        dispatch(
          setTenantConfig({
            data: responseData,
            hashValue: data?.hashValue,
          })
        );
      }
    } catch (err) {
      dispatch(setError(err?.response?.data?.error || "An error occurred"));
      errorHandler({ err: err?.response, dispatch });
    }
  }
);

export const startGenerateOtp = createAsyncThunk(
  "TenantSlice/startGenerateOtp",
  async ({ data, handleSuccess, handleLoader }, { dispatch }) => {
    try {
      const response = await POST(`${apiConstants?.GENERATE_OTP}`, data);
      handleLoader && handleLoader();
      if (response?.status === 200) {
        localStorage.setItem("token", response?.data?.token);
        dispatch(setUserDetails(data));
        handleSuccess && handleSuccess();
      }
    } catch (err) {
      // handleLoader && handleLoader();
      errorHandler({ err: err?.response, handleLoader, dispatch });
      console.log({ err });
    }
  }
);

const TenantSlice = createSlice({
  name: "TenantSlice",
  initialState,
  reducers: {
    setHashValue: (state, action) => {
      state.hashValue = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoaded = true;
    },
    setLoadingFalse: (state, action) => {
      state.isLoaded = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTenantConfig: (state, action) => {
      const data = action?.payload?.data;
      state.areaImages = data?.areaImages;
      const hashValue = action?.payload?.hashValue;
      state.servicesEnabled = data?.activeServices;
      state.fileReadUrl = data?.fileReadUrl;
      state.logo = data?.logoUrl;
      if (data?.isAssetQR) {
        state.isAssetQR = data?.isAssetQR;
        state.faultReportingEnabled = data?.isFaultReportingEnabled;
      }
      if (data?.assetDetails) {
        state.assetDetails = data?.assetDetails;
      }
      state.hashValue = hashValue;
      state.isLoaded = true;
      state.error = "";
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action?.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action?.payload;
    },
  },
});

export const {
  setTenantConfig,
  setUserDetails,
  setHashValue,
  setSelectedOption,
  setLoading,
  setError,
  setLoadingFalse,
} = TenantSlice.actions;

export default TenantSlice.reducer;
