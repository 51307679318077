import {
  Box,
  Drawer,
  Stack,
  Typography,
  IconButton,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomText from "../components/text/CustomText";
import CustomBox from "../components/Box/CustomBox";
import CustomButton from "../components/Button/CustomButton";
import { lightTheme } from "../utils/ThemeProvider";
import bg from "../../assets/bg2.png";
import { useNavigate } from "react-router-dom";
import { setNavigation } from "../redux/slice/NavigationSlice";
import AssetDetailsPage from "../screens/AssetManagement/AssetDetailsPage";
import { useLocation } from "react-router-dom";
import FRAssetsArea from "../screens/FaultReporting/FRAssetsDetails";
import BackgroundImage from "../components/BackgroundImage/BackgroundImage";
import { fetchAreaDetails } from "../redux/slice/feedbackSlice";
// import { setHashValue } from "../redux/slice/TenantSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  startFetchAssetDetails,
  startFetchFrDetails,
} from "../redux/slice/faultReportSlice";
import { NavigationConstants, tenantConstants } from "../common/Constants";
import { setSelectedOption } from "../redux/slice/TenantSlice";
import OverlayLoader from "../components/Overlay/OverlayLoader";
import Lottie from "react-lottie";
import computer from "../../assets/computer.json";
import nhance from "../../assets/Common/nhance_logo.png";
import { Global } from "@emotion/react";
import { getRelativeHeight } from "../utils/RelativeSize";
import NoImage from "../../assets/FaultReport/NoImage.svg";
import feedback from "../../assets/Common/feedback.svg";
import assetManager from "../../assets/Common/assetManager.png";
import errorMessage from "../../assets/Common/errorMessage.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import imgbg from "../../assets/Common/img.jpg";
import Carousel from "react-material-ui-carousel";

const HomePage = (props) => {
  const { hashValue, areaImages, fileReadUrl } = useSelector(
    (state) => state?.tenantConfig
  );
  const dispatch = useDispatch();
  const { isAssetQR } = useSelector((state) => state?.tenantConfig);
  const hashData = hashValue?.toString();
  const [loader, setLoader] = useState(false);

  const { servicesEnabled: optionButtons } = useSelector(
    (state) => state?.tenantConfig
  );
  const { logo } = useSelector((state) => state?.tenantConfig);

  const iconMapping = {
    feedback: feedback,
    faultReport: errorMessage,
    assetManagement: assetManager,
  };

  const handleFeedbackNavigate = () => {
    dispatch(setNavigation("feedback"));
  };
  const handleAssetListNavigate = () => {
    dispatch(setNavigation("assetManagement"));
  };
  const handlePress = (ele) => {
    handleLoader();
    if (ele === tenantConstants?.FAULT_REPORT) {
      dispatch(
        startFetchAssetDetails({
          data: { hashValue },
          handleNavigate,
          handleLoader: handleLoader,
        })
      );
    } else if (ele === tenantConstants?.FEEDBACK) {
      dispatch(
        fetchAreaDetails({
          data: { hashValue },
          handleSuccess: handleFeedbackNavigate,
          handleLoader: handleLoader,
        })
      );
    } else if (ele === tenantConstants?.ASSET_MANAGEMENT) {
      dispatch(
        startFetchFrDetails({
          data: { hashValue },
          handleSuccess: handleAssetListNavigate,
          handleLoader: handleLoader,
        })
      );
    } else {
    }
    dispatch(setSelectedOption(ele));
  };
  const handleNavigate = () => {
    dispatch(setNavigation(NavigationConstants?.FAULT_REPORT));
  };
  const handleLoader = () => {
    setLoader((prev) => !prev);
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isAssetQR ? (
        <>
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Grid pt={10} >
              <img
                src={logo}
                alt={"icon"}
                height="70%"
                width="70%"
                style={{ color: "#000000" }}
              />
            </Grid>
            <AssetDetailsPage />
          </Box>
          {/*
          <AssetDetailsPage /> */}
        </>
      ) : (
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Grid container spacing={0} height={"100%"} p={2}>
            <Grid
              container
              item
              xs={12}
              display={"flex"}
              alignItems="center"
              justifyContent="space-around"
            >
              <Grid item xs={6}>
                <img
                  src={logo}
                  alt={"icon"}
                  height="70%"
                  width="70%"
                  style={{ color: "#000000" }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <QrCodeScannerIcon sx={{ height: 30, width: 30 }} />
                </Box>
              </Grid>
            </Grid>
            {optionButtons?.length !== 0 ? (
              <CustomBox
                sx={{
                  borderRadius: 2,
                  border: "1px solid #e3e6e3",
                  width: "100%",
                  // pb: 10,
                }}
              >
                <Grid container item xs={12} height="35%">
                  <Box
                    sx={{
                      // background: '#808080',
                      display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      overflow: "hidden",
                      borderRadius: 1,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      // position: "relative",
                      height: "100%",
                      // border: "solid",
                      width: "100%",
                    }}
                  >
                    {/* <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        backgroundColor: '#E0E7EC',
                        padding: 0.4,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        textTransform={'uppercase'}
                        // color="primary"
                        // fontWeight="bold"
                      >
                        workstation 2
                      </Typography>
                    </Box> */}
                    {/* <Box style={{ width: "100%" }}> */}
                    {/* <Stack> */}
                    {/* <img
                        src={imgbg}
                        alt="Selected"
                        style={{
                          width: "160%",
                          height: "160%",
                          objectFit: "cover",
                        }}
                      /> */}
                    {areaImages?.length > 0 ? (
                      <Carousel
                        sx={{ width: "100%" }}
                        autoPlay
                        animation="slide"
                        indicators={false}
                        navButtonsAlwaysInvisible={true}
                      >
                        {areaImages?.map((item, i) => (
                          <img
                            src={`${fileReadUrl}${item}`}
                            style={{
                              objectFit: "stretch",
                              // height: 300,
                              width: "100%",
                            }}
                          />
                        ))}
                      </Carousel>
                    ) : (
                      <Box
                        style={{
                          display: "flex",
                          // height: "100%",
                          width: "100%",
                          backgroundColor: "#808080",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={NoImage} style={{ height: 30 }} />
                      </Box>
                    )}
                    {/* </Stack> */}
                    {/* </Box> */}
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  // display={'flex'}
                  // justifyContent={'center'}
                  height="65%"
                  sx={{
                    maxHeight: "300px", // Set a maximum height for the container
                    overflowY: "auto", // Enable vertical scrolling
                    width: "100%", // Make the container fill the available width
                  }} // pt={6}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {optionButtons?.map((ele, index) => {
                      return (
                        <Grid
                          container
                          spacing={2}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems="center"
                          padding={2}
                          textAlign={"center"}
                        >
                          <Grid item xs={12} onClick={() => handlePress(ele)}>
                            {
                              <img
                                src={iconMapping[ele]}
                                alt={"icon"}
                                height="24px"
                                width="24px"
                                style={{ color: "#000000" }}
                              />
                            }
                          </Grid>
                          <Grid item xs={12} key={index}>
                            <Typography
                              variant="subtitle1"
                              fontSize="18px"
                              fontFamily="roboto"
                              onClick={() => handlePress(ele)}
                              textTransform={"uppercase"}
                            >
                              {tenantConstants[ele]}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Box>
                </Grid>
              </CustomBox>
            ) : (
              <Grid
                container
                item
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: computer,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={120}
                    width={120}
                  />
                  <CustomText
                    sx={{
                      fontSize: 24,
                      textAlign: "center",
                      marginTop: 5,
                    }}
                  >
                    No services available
                  </CustomText>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              alignItems="flex-end"
              // paddingBottom={2}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CustomBox sx={{ fontSize: "14px" }}>Powered by</CustomBox>
                <img
                  src={nhance}
                  alt={"icon"}
                  height="35%"
                  width="35%"
                  style={{ color: "#000000" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <OverlayLoader visible={loader} />
    </Box>
  );
};

export default HomePage;
