import { Box, Divider, Grid, Stack, Rating, TextField } from '@mui/material'
import React, { useState } from 'react'
import CustomHeader from '../components/Header/CustomHeader'
import ImageDataCard from '../ui/Widgets/ImageDataCard'
import CustomBox from '../components/Box/CustomBox'
import CustomText from '../components/text/CustomText'
import locationFeedBack from '../../assets/FeedBack/locationFeedBack.png'
import Star from '../../assets/FeedBack/Star.png'
import { getRelativeHeight } from '../utils/RelativeSize'
import StarIcon from '@mui/icons-material/Star'
import CustomButton from '../components/Button/CustomButton'
import { useSelector, useDispatch } from 'react-redux'
import { setNavigation } from '../redux/slice/NavigationSlice'
import {
  clearUserRatingFeedback,
  setUserFeedback,
} from '../redux/slice/feedbackSlice'

const Feedback = () => {
  const [ratings, setRatings] = useState(0)
  const [textFieldValue, setTextFieldValue] = useState('')
  const dispatch = useDispatch()
  const handleTextField = (e) => {
    const val = e.target.value
    setTextFieldValue(val)
  }
  const data = useSelector((state) => state.feedback?.userFeedback)
  const handleFeedbackSubmit = () => {
    const feedbackData = {
      rating: ratings,
      comments: textFieldValue,
      ratingTime: new Date().getTime(),
    }
    dispatch(setUserFeedback(feedbackData))
    dispatch(setNavigation('userDetails'))
  }
  const handleClick = () => {
    dispatch(setNavigation('homePage'))
    dispatch(clearUserRatingFeedback())
  }
  const hashData = useSelector((state) => state.tenantConfig?.hashValue)
  const areaDetails = useSelector(
    (state) => state.feedback?.areaDetails?.data?.areaDetails,
  )
  const overallRating = useSelector(
    (state) => state.feedback?.areaDetails?.data?.averageRating,
  )
  // areaDetails: {
  //   name: "WorkStation 1",
  //   floorDetails: "select floor | nhance SDeg",
  //   overallRating: 3.3,
  // },
  return (
    <>
      {/* <CustomHeader title={"FeedBack"/} /> */}
      <ImageDataCard isTextVisible={false} handleClick={handleClick}>
        <CustomBox
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <CustomBox sx={{ flex: 1 }}>
            <Grid container>
              <Grid item xs={12} mb={1}>
                <CustomText
                  sx={{
                    fontSize: 20,
                    fontWeight: 400,
                    color: '#212121',
                  }}
                >
                  {areaDetails?.area}
                </CustomText>
              </Grid>
              <Grid
                item
                xs={0.5}
                display={'flex'}
                justifyContent="center"
                alignItems="center"
              >
                <img src={locationFeedBack} height={getRelativeHeight(16)} />
              </Grid>
              <Grid item xs={11}>
                <CustomText
                  sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: '#212121',
                    marginLeft: 5,
                  }}
                >
                  {areaDetails?.building} || {areaDetails?.floor}
                </CustomText>
              </Grid>
            </Grid>
          </CustomBox>
          <CustomBox pb={3} pt={3}>
            <Grid container sx={{ mt: 2 }}>
              <Grid
                item
                xs={2}
                display="flex"
                alignItems={'center'}
                justifyContent="flex-start"
                mb={1.5}
              >
                <img src={Star} height="50px" />
              </Grid>
              <Grid item xs={4} display="flex" alignItems={'center'}>
                <Stack>
                  <CustomText
                    sx={{
                      fontSize: 20,
                      fontWeight: 700,
                      color: '#212121',
                    }}
                  >
                    <CustomText
                      sx={{
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#212121',
                      }}
                    >
                      {overallRating !== null && overallRating !== undefined
                        ? overallRating
                        : 'No Ratings'}
                    </CustomText>
                  </CustomText>
                  <CustomText
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: '#212121',
                    }}
                  >
                    Overall Rating
                  </CustomText>
                </Stack>
              </Grid>
              {/* {true && (
                <Grid item xs={6}>
                  <FeedbackProgressBar progressBars={progressBars} />
                </Grid>
              )} */}
            </Grid>
          </CustomBox>
          <Divider />
          <CustomBox p={3}>
            <Stack mt={2}>
              <CustomText
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: '#000000',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Please rate your feedback for this area
              </CustomText>
              <Grid
                display="flex"
                justifyContent="center"
                pt={getRelativeHeight(1)}
              >
                <Rating
                  name="size-large"
                  sx={{ fontSize: '50px' }}
                  value={ratings}
                  onChange={(event, newValue) => {
                    setRatings(newValue)
                  }}
                  precision={1}
                  emptyIcon={
                    <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                  }
                />
              </Grid>
            </Stack>
          </CustomBox>

          <CustomBox>
            <Stack mt={3}>
              <CustomText>Add comments</CustomText>
            </Stack>
            <TextField
              label=""
              placeholder="Enter here"
              multiline
              sx={{
                backgroundColor: '#FFFFFF',
                width: '100%',
                marginBottom: '1vh',
              }}
              value={textFieldValue}
              inputProps={{
                maxLength: 250,
              }}
              onChange={handleTextField}
              rows={4}
            />
            <Stack direction="column" alignItems="flex-end" spacing={1}>
              <CustomText align="right" fontSize={'12px'}>
                {250 - Number(textFieldValue.length)} characters left
              </CustomText>
            </Stack>
          </CustomBox>
          <Box sx={{ paddingTop: 5 }}>
            <CustomButton
              title="Submit"
              onClick={handleFeedbackSubmit}
              //  height={getRelativeHeight(80)}
              disabled={!textFieldValue || !ratings}
            />
          </Box>
        </CustomBox>
      </ImageDataCard>
    </>
  )
}

export default Feedback
