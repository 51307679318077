import {
  Box,
  Drawer,
  IconButton,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Global } from "@emotion/react";
import NoImage from "../../../assets/FaultReport/NoImage.svg";
import backCircle from "../../../assets/Header/backCircle.svg";
import CustomBox from "../../components/Box/CustomBox";
import { lightTheme } from "../../utils/ThemeProvider";
import { getRelativeHeight } from "../../utils/RelativeSize";
import { setNavigation } from "../../redux/slice/NavigationSlice";
import { useDispatch, useSelector } from "react-redux";
import CameraOptionModal from "../../components/Modal/CameraOptionModal";
import CustomText from "../../components/text/CustomText";
import {
  clearImageLink,
  setUpdateImageLink,
} from "../../redux/slice/faultReportSlice";
import Carousel from "react-material-ui-carousel";

const ImageDataCard = (props) => {
  const {
    loaded,
    data,
    height,
    uploadImage,
    handleUploadImage,
    handlePress,
    handleClick,
    handleOpen,
  } = props;
  const [isLoaded, setIsLoaded] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const frImage = useSelector((state) => state?.faultReport?.imageLink);
  const dispatch = useDispatch();
  const { hashValue, areaImages, fileReadUrl } = useSelector(
    (state) => state?.tenantConfig
  );

  // const handleClick = () => {
  //   dispatch(setNavigation('homePage'))
  //   dispatch(clearImageLink())
  //   handlePress && handlePress()
  // }

  // const handleOpen = () => {
  //   setOpenModal(true)
  // }

  // const handleClose = () => {
  //   setOpenModal(false)
  // }
  // const handleImageSelect = (selectedImage) => {
  //   dispatch(setUpdateImageLink(selectedImage))
  // }

  return (
    <CustomBox
      sx={{
        background: "#808080",
        height: 500,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          padding: "10px",
          position: "absolute",
          zIndex: 2,
        }}
      >
        <IconButton size="small" float="left" onClick={handleClick}>
          <img src={backCircle} width={"30px"} />
        </IconButton>
      </Grid>
      <Box onClick={handleOpen}>
        {frImage ? (
          <img
            src={frImage}
            alt="Selected"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <>
            <Box
              sx={{
                padding: props?.isTextVisible
                  ? 15
                  : areaImages?.length > 0
                  ? 0
                  : 15,
                height: !props.isTextVisible
                  ? areaImages?.length > 0
                    ? "320px"
                    : "100px"
                  : "100px",
              }}
            >
              {!props.isTextVisible ? (
                areaImages?.length > 0 ? (
                  <Carousel
                    sx={{ width: "100%" }}
                    autoPlay
                    animation="slide"
                    indicators={false}
                    navButtonsAlwaysInvisible={true}
                  >
                    {areaImages?.map((item, i) => (
                      <img
                        src={`${fileReadUrl}${item}`}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: 320,
                        }}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <IconButton size="small" float="left">
                      <img src={NoImage} width={"25px"} alt="No Image" />
                    </IconButton>
                  </Stack>
                )
              ) : (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <IconButton size="small" float="left">
                    <img src={NoImage} width={"25px"} alt="No Image" />
                  </IconButton>
                </Stack>
              )}
              <Stack
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  onClick={handleOpen}
                  sx={{
                    color: "#ffffff",
                    visibility: props.isTextVisible ? "visible" : "hidden",
                    textAlign: "center",
                  }}
                  variant="subtitle2"
                >
                  Tap to upload an image
                </Typography>
              </Stack>
            </Box>
          </>
        )}
      </Box>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(65%)`,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
        }}
      />
      <Drawer
        anchor="bottom"
        open={true}
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box p={getRelativeHeight(2)}>{props.children}</Box>
      </Drawer>
    </CustomBox>
  );
};
export default ImageDataCard;
