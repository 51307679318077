import React from 'react'
import CustomBox from '../Box/CustomBox'
import { Box } from '@mui/system'

const BackgroundImage = ({ imageUrl, poweredByLogo, children, Logo, sx }) => {
  return (
    <CustomBox
      sx={{
        backgroundImage: `url("${imageUrl}")`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        position: 'relative',
      }}
    >
      {children}
      {Logo && (
        <CustomBox
          sx={{
            position: 'absolute',
            top: 60,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <CustomBox sx={{ paddingRight: sx?.paddingRight || 0 }}>
            <Box sx={{ marginTop: 0.5 }}>
              <img style={{ height: '4vh' }} src={Logo} alt="img" />
            </Box>
          </CustomBox>
        </CustomBox>
      )}
      {poweredByLogo && (
        <CustomBox
          sx={{
            position: 'absolute',
            bottom: 20,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            textAlign: 'center'

          }}
        >
          <CustomBox>
            <CustomBox sx={{ fontSize: '14px' }}>
              Powered by
            </CustomBox>
            <CustomBox sx={{ marginTop: 0.5 }}>
              <img src={poweredByLogo} height="35%" width="35%" />
            </CustomBox>
          </CustomBox>
        </CustomBox>
      )}
    </CustomBox>
  )
}

export default BackgroundImage
