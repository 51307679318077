import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiConstants } from "../../common/Constants";
import { FILEPOST, POST } from "../../api/http";
import { errorHandler } from "../../components/errorHandler";

const initialState = {
  faults: [],
  faultReportDetails: {},
  frDetailsIsLoaded: false,
  location: [],
  qrCode: "",
  assetName: "",
  building: {
    data: [],
  },
  floor: {
    data: [],
  },
  area: {
    data: [],
  },
  faultDetails: {},
  imageLink: "",
  imageFormData: "",
};

export const startFetchFrDetails = createAsyncThunk(
  "FaultReportSlice/startFetchFrDetails",
  async ({ data, handleSuccess, handleLoader }, { dispatch }) => {
    try {
      const response = await POST(`${apiConstants?.QR_ASSETS}`, data);
      if (response?.status === 200) {
        handleLoader && handleLoader();
        const data = response?.data;
        dispatch(setFrDetails(data));
        handleSuccess && handleSuccess();
      }
    } catch (err) {
      handleLoader && handleLoader();
      errorHandler({ err: err?.response, dispatch });
      console.log({ err });
    }
  }
);

export const startFetchAssetDetails = createAsyncThunk(
  "FaultReportSlice/startFetchAssetDetails",
  async ({ data, handleNavigate, handleLoader }, { dispatch }) => {
    try {
      const response = await POST(`${apiConstants?.ASSET_DETAILS}`, data);
      if (response?.status === 200) {
        handleLoader && handleLoader();
        const data = response?.data;
        // console.log({ response: response?.data }, "dattatatatat")
        dispatch(setLocation(data.location));
        dispatch(setBuilding(data.building));
        dispatch(setFloor(data.floor));
        dispatch(setArea(data.area));
        dispatch(setQrCode(data.assetQr))
        // if(data?.area)
        handleNavigate && handleNavigate();
      }
    } catch (err) {
      handleLoader && handleLoader();
      errorHandler({ err: err?.response, handleLoader, dispatch });
      console.log({ err });
    }
  }
);

export const startRaiseFaultReport = createAsyncThunk(
  "FaultReportSlice/startRaise",
  async (
    { data, handleSuccess, handleLoader, toggleInvalidOtp, clearInvalidOtp },
    { dispatch }
  ) => {
    try {
      const response = await FILEPOST(`${apiConstants?.RAISE_FAULT}`, data);
      handleLoader && handleLoader();
      clearInvalidOtp && clearInvalidOtp();
      //   toggleInvalidOtp && toggleInvalidOtp({ success: true });

      if (response?.status === 201) {
        const data = response?.data;
        console.log("fault bodyyyyy", response);
        handleSuccess && handleSuccess();
        // return data;
      }
    } catch (err) {
      handleLoader && handleLoader();
      if (err?.response?.data?.error === "Invalid OTP") {
        toggleInvalidOtp && toggleInvalidOtp();
      }
      // handleLoader && handleLoader();
      errorHandler({ err: err?.response, handleLoader, dispatch });
      console.log({ err });
    }
  }
);

export const faultReportSlice = createSlice({
  name: "faultReportSlice",
  initialState,
  reducers: {
    setFrDetails: (state, action) => {
      state.faultReportDetails = action.payload;
      state.frDetailsIsLoaded = true;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
      state.frDetailsIsLoaded = true;
    },
    setQrCode: (state, action) => {
      state.qrCode = action.payload;
      state.frDetailsIsLoaded = true;
      console.log('aaaaaaaa',action.payload)
    },
    setBuilding: (state, action) => {
      state.building.data = action.payload;
      state.frDetailsIsLoaded = true;
    },
    setFloor: (state, action) => {
      state.floor.data = action.payload;
      state.frDetailsIsLoaded = true;
    },
    setArea: (state, action) => {
      state.area.data = action.payload;
      state.frDetailsIsLoaded = true;
    },
    setAssetName: (state, action) => {
      state.assetName = action?.payload;
    },
    setFaultDetails: (state, action) => {
      state.faultDetails = action?.payload;
    },
    setUpdateImageLink: (state, action) => {
      state.imageLink = action.payload?.url;
      state.imageFormData = action?.payload?.formData;
    },
    clearFaultDetails: (state) => {
      state.faultDetails = initialState.faultDetails;
    },
    clearImageLink: (state) => {
      state.imageLink = initialState.imageLink;
    },
    clearAssetDetails: (state) => {
      state.location = initialState.location;
      state.building.data = initialState.building.data;
      state.floor.data = initialState.floor.data;
      state.area.data = initialState.area.data;
      state.assetName = "";
      state.qrCode = initialState.qrCode
    },
  },
});

export const {
  setFrDetails,
  setBuilding,
  setFloor,
  setArea,
  setLocation,
  setQrCode,
  setFaultDetails,
  setAssetName,
  setUpdateImageLink,
  clearImageLink,
  clearFaultDetails,
  clearAssetDetails,
} = faultReportSlice.actions;
export default faultReportSlice.reducer;
