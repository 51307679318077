import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { CardMedia, Grid, Button, Paper, IconButton, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import NoImage from '../../../../assets/FaultReport/NoImage.svg'
import {
  getRelativeHeight,
  getRelativeWidth,
} from '../../../utils/RelativeSize'
import { lightTheme } from '../../../utils/ThemeProvider'
import CustomBox from '../../../components/Box/CustomBox'
import CustomText from '../../../components/text/CustomText'
import CustomModal from '../../../components/Modal/CustomModal'
import { useSelector } from 'react-redux'
import { apiConstants } from '../../../common/Constants'
import Carousel from 'react-material-ui-carousel'


const AMFaultsDetailsComp = ({
  open,
  handleClose,
  customWidth,
  data,
  showButtons,
  onButton1Click,
  onButton2Click,
  title,
  hideAssetId,
}) => {
  const StyledCardMedia = styled(CardMedia)({
    height: getRelativeHeight(200),
    background: "#AAAAAA",
  });

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  const handleModalClose = () => {
    handleClose();
  };

  const renderCommonGrid = (label, value) => (
    <Grid container item xs={12} key={label}>
      <Grid item xs={4}>
        <CustomText sx={{ fontWeight: "bold" }}>{label}</CustomText>
      </Grid>
      <Grid item xs={8} sx={{ wordBreak: "break-all" }}>
        <CustomText variant="body1">{value}</CustomText>
      </Grid>
    </Grid>
  );

  const getImageUrl = (url) => {
    return `${apiConstants.LOGO_LINK}${
      url.startsWith("/") ? url.slice(1) : url
    }`;
  };

  return (
    <Box>
      <CustomModal open={open} handleClose={handleModalClose}>
        {data.map((item, index) => (
          <Grid item xs={12} key={index}>
            {item?.Image?.length > 0 ? (
              <CustomBox
                sx={{
                  display: "flex",
                  background: "#aaaaaa",
                  height: 300,
                  borderRadius: 4,
                }}
              >
                <Carousel
                  sx={{ width: "100%" }}
                  autoPlay
                  animation="slide"
                  indicators={false}
                  navButtonsAlwaysInvisible
                >
                  {item.Image.map((imgUrl, i) => (
                    <img
                      key={i}
                      src={getImageUrl(imgUrl)}
                      style={{
                        objectFit: "stretch",
                        width: "100%",
                      }}
                      alt={`carousel-img-${i}`}
                    />
                  ))}
                </Carousel>
              </CustomBox>
            ) : (
              <CustomBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#aaaaaa",
                  height: 200,
                  borderRadius: 4,
                }}
              >
                <IconButton size="medium">
                  <img src={NoImage} width="50px" alt="No Image" />
                </IconButton>
              </CustomBox>
            )}
          </Grid>
        ))}
        {data.map((item, index) => (
          <Grid
            container
            spacing={1}
            key={index}
            flexDirection="row"
            sx={{ p: 2 }}
          >
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="div">
                {title}
              </Typography>
            </Grid>
            {renderCommonGrid("Name:", item?.Name)}
            {/* {renderCommonGrid('Asset Id:', item?.id)} */}
            {renderCommonGrid("Model:", item?.Model)}
            {renderCommonGrid("Serial No:", item?.SerialNumber)}

            {showButtons && (
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor:
                      index === 0
                        ? lightTheme?.palette?.orange?.orange4
                        : "error",
                  }}
                  onClick={onButton2Click}
                >
                  Report a fault
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
      </CustomModal>
    </Box>
  );
};

export default AMFaultsDetailsComp;