import { FormControl, OutlinedInput } from '@mui/material'
import React from 'react'
import CustomText from '../text/CustomText'

const FaultDescription = (props) => {
  const { name, value, onChange, maxLength ,title} = props
  return (
    <FormControl fullWidth={true}>
      <CustomText>{title}</CustomText>
      <OutlinedInput
        placeholder="Describe the issue with this asset"
        minRows={5}
        style={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          height: '20%',
          borderRadius: '10px',
        }}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{ maxLength: 250, ...maxLength }}
        multiline={true}
      />
    </FormControl>
  )
}

export default FaultDescription
