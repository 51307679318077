import React, { useState, useEffect, useRef } from 'react'
import CustomBox from '../components/Box/CustomBox'
import CustomHeader from '../components/Header/CustomHeader'
import { Grid, TextField, Typography, Box, Link } from '@mui/material'
import CustomText from '../components/text/CustomText'
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles'
import Img from '../../assets/Common/Img.svg'
import CustomButton from '../components/Button/CustomButton'
import bg from '../../assets/bg2.png'
import animationData from '../../assets/Common/Otp.json'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
import { startRaiseFaultReport } from '../redux/slice/faultReportSlice'
import {
  FBConstants,
  NavigationConstants,
  OtpInputConstants,
  tenantConstants,
} from '../common/Constants'
import { startUserRating } from '../redux/slice/feedbackSlice'
import { startGenerateOtp } from '../redux/slice/TenantSlice'
import { setNavigation } from '../redux/slice/NavigationSlice'
import { lightTheme } from '../utils/ThemeProvider'
import { resetAlert, setToastMessage } from '../redux/slice/Common/toastSlice'
import AlertComponent from '../../helpers/AlertComponent'
import OverlayLoader from '../components/Overlay/OverlayLoader'
import { getRelativeHeight } from '../utils/RelativeSize'

const OtpInput = ({ length = 6 }) => {
  const { userDetails, selectedOption, hashValue } = useSelector(
    (state) => state?.tenantConfig,
  )
  const alertMessage = useSelector((state) => state?.ToastSlice)
  const { isAssetQR } = useSelector((state) => state?.tenantConfig)
  const [invalidOtp, setInvalidOtp] = useState(false)
  const userRatingInfo = useSelector((state) => state.feedback?.userFeedback)
  const faultDetails = useSelector((state) => state?.faultReport?.faultDetails)

  const dispatch = useDispatch()
  let theme = createTheme()
  theme = responsiveFontSizes(theme)
  const [inputValues, setInputValues] = useState(Array(length).fill(''))
  const [seconds, setSeconds] = useState(60)
  const [email, setEmail] = useState(userDetails?.email || '')
  const [phone, setPhone] = useState(userDetails?.mobile || '')
  const [loader, setLoader] = useState(false)
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const frImage = useSelector((state) => state?.faultReport?.imageFormData)
  //   console.log(Array.from(frImage.entries()));
  const inputRefs = useRef(
    Array(length)
      .fill(null)
      .map(() => React.createRef()),
  )
  // const handleInputChange = (index, value) => {
  //   const newValues = [...inputValues];
  //   newValues[index] = value;
  //   console.log({ index, value, newValues });
  //   setInputValues(newValues);
  //   if (value && index < length - 1) {
  //     // setTimeout(() => {
  //     inputRefs.current[index + 1].current.focus();
  //     // }, 50);
  //   }
  // };

  const handleLoader = () => {
    setLoader((prev) => !prev)
  }

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newValues = [...inputValues]
      newValues[index] = value
      setInputValues(newValues)

      if (value && index < length - 1) {
        inputRefs.current[index + 1].current.focus()
      }
    }
  }

  //   const handleResendClick = () => {
  //     setSeconds(60)
  //     setIsResendDisabled(true)
  //   }
  const handleResendClick = () => {
    if (seconds === 0) {
      setIsResendDisabled(false)
      // Add your logic here to resend the OTP if needed
    }
    setSeconds(60)
    setIsResendDisabled(true)
  }

  const startTimer = () => {
    setSeconds(60)
    setIsResendDisabled(true)

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(timer)
          setIsResendDisabled(false)
        }
        return prevSeconds - 1
      })
    }, 1000)
  }

  const isFilled = (value) => value !== ''

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 1) {
          clearInterval(timer)
          setIsResendDisabled(false)
        }
        return prevSeconds - 1
      })
    }, 1000)

    setIsResendDisabled(true)

    return () => clearInterval(timer)
  }, [])

  const handleSuccess = () => {
    dispatch(setNavigation(NavigationConstants?.GRATITUDE_MESSAGE))
  }
  const handleClick = () => {
    dispatch(setNavigation('userDetails'))
  }

  // const handleKeyDown = (index, event) => {
  //   if (event.key === "Backspace" && index > 0) {
  //     const newValues = [...inputValues];
  //     newValues[index - 0] = "";
  //     setInputValues(newValues);
  //     inputRefs.current[index - 1].current.focus();
  //   }
  // };
  //   setLoader((prev) => !prev);
  // };
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && inputValues[index] === '') {
      const newValues = [...inputValues]
      newValues[index - 1] = ''
      setInputValues(newValues)
      inputRefs.current[index - 1].current.focus()
    }
  }
  const handleSubmitOtp = () => {
    if (inputValues?.every((ele) => ele !== '')) {
      if (
        selectedOption === tenantConstants?.FAULT_REPORT ||
        isAssetQR ||
        selectedOption === tenantConstants?.ASSET_MANAGEMENT
      ) {
        // dispatch()
        let data = {
          title: faultDetails?.title,
          issue: faultDetails?.issue,
          reportedOn: Date.now(),
        };
        if (faultDetails?.description)
          data.description = faultDetails?.description
        if (faultDetails?.assetQr) data.assetQr = faultDetails?.assetQr
        if (faultDetails?.file) data.file = faultDetails?.file
        const formData = new FormData()
        if (frImage) formData.append('file', frImage)
        formData.append('fault', JSON.stringify(data))
        formData.append('hashValue', hashValue)
        formData.append('otpValue', String(inputValues?.join('')))

        dispatch(
          startRaiseFaultReport({
            data: formData,
            handleSuccess,
            handleLoader: handleLoader,
            toggleInvalidOtp: toggleInvalidOtp,
            clearInvalidOtp: clearInvalidOtp,
          }),
        )
      } else if (selectedOption === tenantConstants?.FEEDBACK) {
        dispatch(
          startUserRating({
            data: {
              hashValue,
              ratingObj: userRatingInfo,
              otpValue: String(inputValues?.join('')),
            },
            handleSuccess,
            handleLoader: handleLoader,
            toggleInvalidOtp: toggleInvalidOtp,
            clearInvalidOtp: clearInvalidOtp,
          }),
        )
      }
    }
  }
  const handleNavigate = () => {
    dispatch(
      setToastMessage({ message: 'OTP sent successfully', type: 'success' }),
    )

    startTimer()
    // inputValues('')
  }
  const toggleInvalidOtp = () => {
    setInvalidOtp((prev) => !prev)
  }
  const clearInvalidOtp = () => {
    setInvalidOtp(false)
  }
  const handleResendOtp = () => {
    setInputValues(Array(length).fill(''))
    handleLoader()
    let data = {
      hashValue,
      userName: userDetails?.userName,
    }
    if (email) {
      data.email = email
    }

    if (phone) {
      data.mobile = phone
    }
    dispatch(
      startGenerateOtp({
        data,
        handleSuccess: handleNavigate,
        handleLoader: handleLoader,
      }),
    )
  }
  return (
    <Box>
      <CustomHeader handleClick={handleClick} />
      <CustomBox
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid contain sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Lottie
              options={{
                animationData: animationData || {},
                loop: true,
                autoplay: true,
              }}
              style={{ width: 350, height: 200 }}
            />
          </Grid>
          <ThemeProvider theme={theme}>
            <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
              <Typography variant="h4">
                {OtpInputConstants.ENTER_YOUR}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
              <Typography variant="h4">
                {OtpInputConstants.VERIFICATION_CODE}
              </Typography>
            </Grid>
            <Grid item container pt={1} sx={{ color: '#2b2a2a' }}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {OtpInputConstants.WE_HAVE_SENT}
                </Typography>
              </Grid>
              {userDetails?.email && userDetails?.mobile ? (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {`to ${userDetails.email} and ${userDetails.mobile}`}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {`to ${
                      userDetails?.email
                        ? userDetails.email
                        : userDetails.mobile
                    }`}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '5ch', pt: 2 },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              noValidate
              autoComplete="off"
            >
              {inputValues.map((value, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  color="error"
                  size="small"
                  value={value}
                  type="number"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center',
                      borderColor: isFilled(value) ? '#000000' : '#AAAAAA',
                      color: isFilled(value) ? '#000000' : '#AAAAAA',
                      fontSize: 20,
                    },
                  }}
                  inputRef={inputRefs.current[index]}
                />
              ))}
            </Box>
            {invalidOtp && (
              <CustomBox sx={{ marginTop: 0.5 }}>
                <CustomText sx={{ color: '#cc0000' }}>
                  {OtpInputConstants.INVALID_OTP}
                </CustomText>
              </CustomBox>
            )}
            <Grid container item xs={12} sx={{ padding: 10 }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CustomText>{`Request for another OTP in ${seconds}s`}</CustomText>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleResendOtp}
                  disabled={seconds !== 0}
                  sx={{
                    color:
                      seconds !== 0
                        ? lightTheme?.palette?.grey?.grey2
                        : lightTheme?.palette?.orange?.orange2,
                  }}
                >
                  {OtpInputConstants.RESEND}
                </Link>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Grid>
      </CustomBox>
      <Box>
        <CustomBox sx={{ paddingX: 4 }}>
          {selectedOption === tenantConstants?.FEEDBACK ? (
            <CustomText sx={{ textAlign: 'center', fontSize: 14 }}>
              {OtpInputConstants.FEEDBACK_SUBMIT}
            </CustomText>
          ) : (
            <CustomText sx={{ textAlign: 'center', fontSize: 14 }}>
              {OtpInputConstants.FAULT_SUBMIT}
            </CustomText>
          )}
        </CustomBox>
        <CustomBox sx={{ marginTop: 3, paddingX: 4, paddingBottom: 4 }}>
          <CustomButton
            // height={getRelativeHeight(55)}
            title="Submit"
            onClick={handleSubmitOtp}
            disabled={!inputValues?.every((ele) => ele !== '')}
          />
        </CustomBox>
      </Box>
      <OverlayLoader visible={loader} />
    </Box>
  )
}

export default OtpInput
