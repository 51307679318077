import React from "react";
import CustomBox from "../../components/Box/CustomBox";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import CustomText from "../../components/text/CustomText";
import { getRelativeWidth, getRelativeHeight } from "../../utils/RelativeSize";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Lottie from "react-lottie";
import noServices from "../../../assets/notFound.json";

const AmAssetsList = (props) => {
  const {
    faultReportDetails,
    currentPage,
    pageSize,
    handleItemClick,
    handlePageChange,
    totalPages,
    showArrow,
  } = props;

  return (
    // <CustomBox>
    //   <Stack sx={{ padding: 2 }} direction={'row'} spacing={6}>
    //     <CustomText sx={{ fontSize: 24, fontWeight: '500' }}>
    //       Assets found in the area
    //     </CustomText>
    //     {/* <CustomText sx={{ fontSize: 20 }}>Skip</CustomText> */}
    //   </Stack>
    //   <List>
    //     {faultReportDetails
    //       ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    //       .map(({ _id, assetName, model, serialNumber, imageLinks }, index) => (
    //         <React.Fragment key={_id}>
    //           <ListItemButton
    //             key={index}
    //             onClick={() =>
    //               handleItemClick({
    //                 _id,
    //                 assetName,
    //                 model,
    //                 serialNumber,
    //                 imageLinks,
    //               })
    //             }
    //           >
    //             <ListItemAvatar>
    //               {imageLinks && imageLinks.length > 0 ? (
    //                 <Avatar alt="Profile Picture" src={imageLinks[0]} />
    //               ) : (
    //                 <Avatar />
    //               )}
    //             </ListItemAvatar>
    //             <CustomBox sx={{ marginLeft: showArrow ? 0 : 2 }}>
    //               <ListItemText
    //                 primary={assetName}
    //                 secondary={`Model: ${model}`}
    //               />
    //             </CustomBox>

    //             {showArrow && (
    //               <ListItemIcon sx={{ marginLeft: '10px' }}>
    //                 <ArrowForwardIosIcon />
    //               </ListItemIcon>
    //             )}
    //           </ListItemButton>
    //           <Divider />
    //         </React.Fragment>
    //       ))}
    //   </List>
    //   <Box
    //     display="flex"
    //     justifyContent="center"
    //     sx={{
    //       paddingTop: 2,
    //       paddingBottom: 3,
    //     }}
    //   >
    //     <Pagination
    //       count={totalPages}
    //       page={currentPage}
    //       onChange={(event, page) => handlePageChange(page)}
    //       color="primary"
    //     />
    //   </Box>
    // </CustomBox>

    // <Card
    //   sx={{
    //     // height: "60%",
    //     display: "flex",
    //     alignItem: "center",
    //     justifyContent: "center",
    //     flexDirection: "column",
    //     width: "90%",
    //     height: "100%",
    //   }}
    // >
    <Box>
      {faultReportDetails?.length > 0 ? (
        <>
          <CustomBox sx={{ p: 2 }} xs={12}>
            <Typography variant="h6" fontWeight="bold">
              Assets found in the area
            </Typography>
          </CustomBox>
          <Grid
            container
            item
            sx={{
              flex: 1,
              overflowY: "auto",
              position: "relative",
            }}
            xs={12}
          >
            {faultReportDetails
              ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
              .map(
                (
                  {
                    _id,
                    assetName,
                    model,
                    serialNumber,
                    imageLinks,
                    area,
                    floor,
                    location,
                    building,
                    qrCode,
                  },
                  index
                ) => (
                  <Grid item xs={12} key={_id}>
                    <>
                      <ListItemButton
                        onClick={() =>
                          handleItemClick({
                            _id,
                            assetName,
                            model,
                            serialNumber,
                            imageLinks,
                            area,
                            floor,
                            location,
                            building,
                            qrCode,
                          })
                        }
                      >
                        <ListItemAvatar>
                          {imageLinks && imageLinks.length > 0 ? (
                            <Avatar alt="Profile Picture" src={imageLinks[0]} />
                          ) : (
                            <Avatar />
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={assetName}
                          secondary={`Model: ${model}`}
                        />
                        <ListItemIcon sx={{ marginLeft: "10px" }}>
                          <ArrowForwardIosIcon />
                        </ListItemIcon>
                      </ListItemButton>
                      <Divider />
                    </>
                  </Grid>
                )
              )}
          </Grid>
        </>
      ) : (
        <>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Lottie
                options={{
                  animationData: noServices || {},
                  loop: true,
                  autoplay: true,
                }}
                style={{
                  width: getRelativeWidth(300),
                  height: getRelativeHeight(300),
                }}
              />
              <Box>
                <Typography variant="h6" textAlign={"center"}>
                  No Assets found in the area
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {faultReportDetails?.length > 0 && (
        <Grid container item justifyContent="center">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
            color="error"
          />
        </Grid>
      )}
      {/* </Box> */}
    </Box>
    // </Card>
  );
};

export default AmAssetsList;
