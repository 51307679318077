import React, { useEffect, useState } from 'react'
import CustomBox from '../../components/Box/CustomBox'
import ImageDataCard from '../../ui/Widgets/ImageDataCard'
import CustomText from '../../components/text/CustomText'
import CustomNameInput from '../../components/InputField/CustomNameInput'

import ReusableRadioGroup from '../../components/InputField/ReusableRadioGroup'
import FaultDescription from '../../components/InputField/FaultDescription'
import CustomButton from '../../components/Button/CustomButton'
import { Box, Stack } from '@mui/material'
import {
  clearAssetDetails,
  clearImageLink,
  setFaultDetails,
  setUpdateImageLink,
  startFetchAssetDetails,
  startFetchFrDetails,
  startRaiseFaultReport,
} from '../../redux/slice/faultReportSlice'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setNavigation } from '../../redux/slice/NavigationSlice'
import CustomModal from '../../components/Modal/CustomModal'
import FRAssetsArea from './FRAssetsDetails'
import AmAssetsList from '../../ui/Widgets/AmAssetsList'
import { NavigationConstants } from '../../common/Constants'
import OverlayLoader from '../../components/Overlay/OverlayLoader'
import { getRelativeHeight } from '../../utils/RelativeSize'
import CameraOptionModal from '../../components/Modal/CameraOptionModal'

const FRAssetsRaiseFault = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const availableServices = useSelector(
    (state) => state?.tenantConfig?.servicesEnabled,
  )
  const isAMAvailable = availableServices?.includes('assetManagement')
  // const isAMAvailable = Array.isArray(availableServices) && availableServices.includes("assetManagement");

  const { hashValue } = useSelector((state) => state?.tenantConfig)
  const faultReportDetails = useSelector(
    (state) => state?.faultReport?.faultReportDetails?.data,
  )
   const qrCode = useSelector((state) => state?.faultReport?.qrCode);
  const pageSize = 5

  const totalItems = faultReportDetails?.length
  const totalPages = Math.ceil(totalItems / pageSize)
  const dispatch = useDispatch()
  const [radioButton, setRadioButton] = useState('')
  const [other, setOther] = useState('')
  const [description, setDescription] = useState('')
  const [assetListModal, setAssetListModal] = useState(false)
  const [assetQr, setAssetQr] = useState('')
  const [loader, setLoader] = useState(false)
  const building = useSelector((state) => state?.faultReport?.building?.data)
  
  const Assetlocation = useSelector((state) => state?.faultReport?.location)
  const area = useSelector((state) => state?.faultReport?.area?.data)
  const floor = useSelector((state) => state?.faultReport?.floor?.data)
  const { assetName } = useSelector((state) => state?.faultReport)
  const frDetailsIsLoaded = useSelector(
    (state) => state?.faultReport?.frDetailsIsLoaded,
  )
  const { isAssetQR } = useSelector((state) => state?.tenantConfig)

  const [selectedAsset, setSelectedAsset] = useState(assetName || '')
  //  const [selectedAssetQr, setSelectedAssetQr] = useState("");
   const selectedAssetQr = useSelector((state) => state?.faultReport?.qrCode);
  const [openModal, setOpenModal] = useState(false)

  const handleOpen = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }
  const handleImageSelect = (selectedImage) => {
    dispatch(setUpdateImageLink(selectedImage))
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }
  const handleChange = (e) => {
    setRadioButton(e.target.value)
  }
  const handleChangeRadio = (e) => {
    setOther(e.target.value)
  }

  const handleChangeDescription = (e) => {
    setDescription(e.target.value)
  }

  const handleChangeAssetDetails = (e) => {
    setSelectedAsset(e.target.value)
  }

  // const handleOpenCamera = () => {
  //   console.log("open camera")
  // }

  const handleNavigate = () => {
    // dispatch(setNavigation("userDetails"))
    const data = {
      title: selectedAsset,
      issue: radioButton === "other" ? other : radioButton,
      description: description || "",
      assetQr: selectedAssetQr,
    };
  
    dispatch(setFaultDetails(data))
    dispatch(setNavigation(NavigationConstants?.USER_DETAILS))
    // dispatch(startRaiseFaultReport({ data }));
  }

  const handleItemClick = (data) => {
    setSelectedAsset(data?.assetName)
    setAssetListModal(false)
  }

  const handleSuccess = () => {
    setAssetListModal(true)
  }
  const handleClearSelectedAsset = () => {
    setSelectedAsset('')
  }
  const handleFetchAssetList = () => {
    dispatch(
      startFetchFrDetails({
        handleSuccess,
        data: { hashValue },
        handleLoader: handleLoader,
      }),
    )
  }
  const handleLoader = () => {
    setLoader(false)
  }
  const handleClick = () => {
    dispatch(setNavigation('homePage'))
    dispatch(clearAssetDetails())
    dispatch(clearImageLink())
  }

  const handleBackClick = () => {}

  return (
    <ImageDataCard
      isTextVisible={true}
      handleClick={handleClick}
      handleOpen={handleOpen}
    >
      <CustomBox>
        <CustomText>Please add asset details</CustomText>
        {frDetailsIsLoaded && (
          <CustomNameInput
            placeholder="Select the location"
            title="Select the location"
            inputValue={Assetlocation}
            disabled={Assetlocation}
          />
        )}
        {frDetailsIsLoaded && (
          <CustomNameInput
            title="Select the building of the asset being reported"
            placeholder="Select the building"
            inputValue={building}
            disabled={building}
          />
        )}
        {frDetailsIsLoaded && (
          <CustomNameInput
            title="Select the floor of the asset"
            placeholder="Select the floor"
            inputValue={floor}
            disabled={floor}
          />
        )}
        {frDetailsIsLoaded && (
          <CustomNameInput
            placeholder="Select the area of the asset"
            title="Select the area of the asset"
            inputValue={area}
            disabled={area}
          />
        )}
        <CustomNameInput
          name="input5"
          placeholder="Enter the name of the asset"
          title="Name of the Asset"
          inputValue={selectedAsset}
          onChange={handleChangeAssetDetails}
          crossIcon={!assetName && selectedAsset}
          disabled={assetName}
          handleCrossIconClick={handleClearSelectedAsset}
        />
        {!selectedAsset && !isAssetQR && (
          <CustomBox sx={{ width: '100%' }}>
            {isAMAvailable && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: 0.5,
                  justifyContent: 'flex-end',
                }}
                onClick={handleFetchAssetList}
              >
                <CustomText sx={{ fontSize: 12, color: '#595959' }}>
                  Want to choose an asset in this area?
                </CustomText>
              </Box>
            )}
          </CustomBox>
        )}

        <Stack spacing={2}>
          <Stack pt={2}>
            <ReusableRadioGroup
              title="What is wrong with this asset?"
              radioButton={radioButton}
              handleChange={handleChange}
              handleChangeRadio={handleChangeRadio}
              other={other}
            />
          </Stack>
          <Stack>
            <FaultDescription
              title="Add Description(Optional)"
              placeholder="Enter name"
              value={description}
              name="description"
              onChange={(e) => handleChangeDescription(e)}
              maxLength={{ maxLength: 250 }}
            />
          </Stack>
          <Stack>
            <CustomButton
              height={getRelativeHeight(55)}
              title="Submit"
              onClick={handleNavigate}
              disabled={!selectedAsset || !radioButton}
            />
          </Stack>
        </Stack>
      </CustomBox>
      <CustomModal
        open={assetListModal}
        handleClose={() => setAssetListModal(false)}
      >
        <AmAssetsList
          faultReportDetails={faultReportDetails}
          currentPage={currentPage}
          pageSize={pageSize}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handleItemClick={handleItemClick}
        />
      </CustomModal>
      {openModal && (
        <CameraOptionModal
          isOpen={handleOpen}
          onClose={handleClose}
          onImageSelect={handleImageSelect}
        />
      )}
      <OverlayLoader visible={loader} />
    </ImageDataCard>
  )
}
export default FRAssetsRaiseFault
