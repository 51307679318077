import React from 'react'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { getRelativeHeight, getRelativeWidth } from '../../utils/RelativeSize'

const CustomModal = (props) => {
  const {
    open,
    handleClose,
    children,
    customMinHeight,
    customWidth,
    padding,
  } = props
  const theme = useTheme()

  const defaultMinHeight = '50vh'
  const defaultWidth = '90vw'

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{ invisible: true }}
    >
      <Paper
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: customWidth || defaultWidth,
          top: '50%',
          outline: '0',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          // padding: padding || 2,
          minHeight: customMinHeight || defaultMinHeight,
          [theme.breakpoints.down('xs')]: {
            width: '90vw',
          },
        }}
      >
        {children}
      </Paper>
    </Modal>
  )
}

export default CustomModal
