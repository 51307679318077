// import CustomTypography from "./app/components/Text/CustomTypography";
import {
  Box,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Navigation from "./app/navigation/Navigation";
import { lightTheme } from "./app/utils/ThemeProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClientType } from "./app/redux/slice/NavigationSlice";
import { getRelativeWidth } from "./app/utils/RelativeSize";
import loaderAnim from "./assets/loader.json";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import {
  setError,
  setLoadingFalse,
  setTenantConfig,
  startFetchTenantConfig,
} from "./app/redux/slice/TenantSlice";
import AlertComponent from "./helpers/AlertComponent";
import OverlayLoader from "./app/components/Overlay/OverlayLoader";
import NoServicePage from "./app/screens/NoServicePage";
import { GET_CSRF } from "./app/api/http";
import axios from "axios";

function App() {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state?.ToastSlice);
  const location = useLocation();
  const theme = useTheme();
  const [loader, setLoader] = useState(false);
  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"));
  const { accessEnabled, isLoaded, servicesEnabled, hashValue } = useSelector(
    (state) => state?.tenantConfig
  );
  const errorMessage = useSelector((state) => state?.tenantConfig?.error);
  const showNoServicePage =
    errorMessage && errorMessage.includes("Service is not active");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnim,
  };
  const height = window.innerHeight;
  const width = window.innerWidth;

  const handleLoader = () => {
    setLoader((prev) => !prev);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}v1/csrf`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response?.status === 200) {
          localStorage.setItem("csrfToken", response?.data?.csrf_token);
          const encodedString = location.pathname
            ?.split(process.env.REACT_APP_URL)[0]
            ?.slice(1);

          const data = { hashValue: encodedString };
          // alert(encodedString)
          dispatch(startFetchTenantConfig({ data }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const client = isMobileOrTab ? "pwa" : "web";
    dispatch(setClientType({ height, width, client }));
  }, [height, width]);

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu); //After event handler is executed,remove it to prevent memeory leaks
    };
  }, []);

  //clickJacking prevention - frameBusting code
  useEffect(() => {
    if (window !== window.top) {
      window.top.location = window.location;
    }
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        height: window.innerHeight,
        justifyContent: "center",
        display: "flex",
        // border: "solid 1px red",
      }}
    >
      <Box
        sx={{
          maxWidth: "600px",
          height: "100%",
          width: "100%",
        }}
      >
        {/* <ThemeProvider theme={lightTheme}> */}
        {showNoServicePage ? (
          <NoServicePage />
        ) : (
          <>
            {isLoaded ? (
              <>
                <Navigation />
                {alertMessage?.message && (
                  <AlertComponent
                    errorMessage={alertMessage?.message}
                    type={alertMessage?.type}
                  />
                )}
              </>
            ) : (
              <Lottie options={defaultOptions} height={250} width={250} />
            )}
          </>
        )}
        {/* </ThemeProvider> */}
      </Box>
      <OverlayLoader visible={loader} />
    </Box>
  );
}

export default App;
