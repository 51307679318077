import React from 'react'
import CustomBox from '../components/Box/CustomBox'
import FRAssetsArea from '../screens/FaultReporting/FRAssetsDetails'

const AssetManagement = () => {
  return (
    <CustomBox>
      <FRAssetsArea />
    </CustomBox>
  )
}
export default AssetManagement
