import { createTheme } from "@mui/material/styles";

const baseTheme = createTheme({
  typography: {
    fontFamily: "roboto",
    fontSize: 12,

    fontFamily: "'roboto','Open sans','Helvetica'",
  },
});

const lightTheme = createTheme({
  defaultFontFamily: "Roboto",
  palette: {
    background: {
      // default: '#F6F6F6',
      default: "#FFFFFF",
      transparent: "transparent",
    },
    nhance: {
      dim: "#808080",
      background: "#F7F7F7",
      grey2: "#D9D9D9",
    },
    grey: {
      dim: "#808080",
      grey1: "#AAAAAA",
      grey2: "#D9D9D9",
      grey3: "#4B4B4B",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    white: {
      main: "#ffffff",
      contrastText: "#000000",
      white1: "#E0E0E0",
      white2: "#",
      lightWhite: "#EBEBE4",
    },
    orange: {
      orange: "#FF6520",
      orange2: "#ff5722",
      orange3: "#D97D54",
      orange4: "#DC5D3B",
      contrastText: "#fff",
    },
    red: {
      red1: "#FF4E5E",
      errorRed: "#FF9494",
    },
    blue: {
      blue1: "#64748B",
      blue2: "#02B9A4",
      blue3: "#E8EDFF",
    },

    black: {
      black1: "#212121",
      black2: "#4B4B4B",
      black3: "#1F1F1F",
      black4: "#201F1E",
    },
    grey: {
      grey1: "#AAAAAA",
      grey2: "#BABABA",
      grey3: "#c4c4c4",
    },
    disabledTextColor: {
      lightGrey: "#cccccc",
      black3: "#555",
      black4: "#000000DE",
      lightBlack: "#dcdcdc",
    },
    green: {
      lightGreen: "#adff2f",
      green2: "#DCDF35",
      green3: "#4BB543",
    },
    yellow: {
      yellow: "#FEC601",
      yellow2: "#C7D42F",
      yellow3: "#CFBD1C",
      yellow4: "#FFFFF8",
    },
  },
});

export { lightTheme };
