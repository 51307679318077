import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FBConstants } from '../../common/Constants'
import { GET } from '../../api/http'
import { setToastMessage } from './Common/toastSlice'

const initialState = {
  areaAssets: [],
  areaAssetsIsLoaded: false,
  assetDetails: {},
  assetDetailsIsLoaded: false,
}


// export const startFetchAssetByQr = createAsyncThunk(
//   async ({dispatch}) => {
//     try {
//       const response = await GET(``);

//     //   const response = await QRGET(`v1/asset/details/by-qrcode?code=${qrCode}`);
//       if (response.status === 200) {
//         // dispatch(updateAssetByQrData(response?.data));
//         // toggleLoader && toggleLoader();
//         // handleSuccess && handleSuccess(response?.data);
//         //   data.handleSubmitSuccessful();
//         // return response.data;
//       } else {
//         // toggleLoader && toggleLoader();
//       }
//     } catch (err) {
//       // toggleLoader && toggleLoader();
//       if(err?.response?.status===404){
//         dispatch(setToastMessage({message:'No asset found'}))
//       }
//       console.log({err});
//     }
//   },
// ) 


const assetDetailsSlice = createSlice({
  name: 'assetDetailsSlice',
  initialState,
  reducers: {
    setAssetDetails: (state, action) => {
      state.areaDetails = action.payload
    },
  },
})
export const { setAssetDetails } = assetDetailsSlice.actions
export default assetDetailsSlice.reducer
