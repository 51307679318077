import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  messageLoaded: false,
  type: "",
};

const ToastSlice = createSlice({
  name: "ToastSlice",
  initialState,
  reducers: {
    setToastMessage: (state, action) => {
      state.message = action.payload?.message || "";
      state.messageLoaded = true;
      state.type = action.payload?.type;
    },
    resetAlert: (state, action) => {
      state.message = "";
      state.messageLoaded = false;
    },
  },
});

export const { setToastMessage, resetAlert } = ToastSlice.actions;

export default ToastSlice.reducer;
